import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { makeStyles } from "@material-ui/core/styles";
import qrcode from "qrcode-js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function CotizacionesTiendasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COTIZACIONES_TIENDAS = process.env.REACT_APP_URL_COTIZACIONES_TIENDAS;
  const URL_ARTICULOS_COTIZACIONES_TIENDAS =
    process.env.REACT_APP_URL_ARTICULOS_COTIZACIONES_TIENDAS;



  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorName, setSelectedColaboradorName] = useState("");
  const [selectedColaboradorInput, setSelectedColaboradorInput] = useState("");
  const [value, setValue] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [cliente, setCliente] = useState("");
  const [inventarios, setInventarios] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [articulosUnicos, setArticulosUnicos] = useState([]);

  const [fecha, setFecha] = useState(hoy);
  const [condPago, setCondPago] = useState("Contado");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);



  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInv: "",
      cantInv: 0,
      articulos: "",
      articulosNombre: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      total: 0,
      linea:"",
      familia:"",
      marca:""
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);



  useMemo(() => {
    setSelectedArea(user.areas);
    if(user.areas){
    axios
      .get(`${URL_INVENTARIOS}AcumuladoAlmacen/${user.areas}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);
        setSelectedAreaName(res.data[0].area);
        let result = allInventarios.map((a) => a.idArticulo);
        setIdsArticulos(result);

        let unicos = allInventarios.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idArticulo === value.idArticulo)
        );
        setArticulosUnicos(unicos);
      })
      .catch((err) => {
        console.log(err);
      });

      
    axios
    .get(`${URL_CLIENTES}Area/${user.areas}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allClientes = res.data;
      setClientes(allClientes);
    })
    .catch((err) => {
      console.log(err);
    });
  }

    axios
    .get(`${URL_COLABORADORES}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allColaboradores = res.data;
      setColaboradores(allColaboradores);
    })
    .catch((err) => {
      console.log(err);
    });


  }, [user]);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      })

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveVenta = async (event) => {
    event.preventDefault();

      if (!value._id) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Selecciona un Cliente",
        });
      } else {
        toggleProgreso();
        let totalPedido = inputFields.length;

        try {
          await axios
            .post(
              URL_COTIZACIONES_TIENDAS,
              {
                fecha,
                clientes: value._id,
                colaboradores: selectedColaborador,
                total_general:(total_general).toFixed(2),
                observaciones,
                total_cantidad,
                areas: selectedArea,
                comisionTDC,
                comisionFlete,
                subTotal:(subTotal).toFixed(2),
                iva:(iva).toFixed(2),
                impuestos: selectedImpuesto,
                vendido:"No"
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((data) => {
              inputFields.map((a) => {
                if (a.articulos != "" && a.cantidad != 0) {
                  axios
                    .post(
                      URL_ARTICULOS_COTIZACIONES_TIENDAS,
                      {
                        cotizacionesTiendas: data.data._id,
                        articulos: a.articulos,
                        cantidad: parseFloat(a.cantidad),
                        precio: parseFloat(a.precio),
                        total: parseFloat(a.total),
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      totalPedido = totalPedido - 1;
                      if (totalPedido == 0) {
                        PDFOC(data.data.idCotizacionTienda)
                        axios
                          .post(
                            URL_LOGS,
                            {
                              tipo: "Crear Cotizacion",
                              detalle: `${value._id} ${total_general}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then(() => {
                            Swal.fire(
                              "Good job!",
                              "Creado con exito",
                              `success`
                            );
                            setModalProgreso(false);
                            // clear();
                            setTimeout(() => {
                              window.location.reload();
                            }, 1000);
                          });
                      }
                    });
                } else {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Cotizacion",
                          detalle: `${value._id} ${total_general}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", `success`);
                        setModalProgreso(false);
                        // clear();
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      });
                  }
                }
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response}`,
              });
              console.log(error);
              setValidaBoton(true);
            });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response}`,
          });
          console.log(error);
        }
      }
    
  };

  async function PDFOC(numero) {

    let cajas = inputFields.map((c) => parseFloat(c.cajas));
    let TC = cajas.reduce((t, total, index) => t + total, 0);

    let LA = (inputFields.length)*1.5
    let largoTotal = LA + 6 + 7

        const data = inputFields.map((a) => {
          return [
            a.articulosNombre + " " + a.linea + " " + a.familia + " " + a.marca,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("p", "cm", [8,largoTotal], true);

        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 5, 1, 2, 2);

        doc.setFontSize(6);
        doc.text(`Chamap SA de CV / RFC: CAM210616LD9`, .3, 1);
        doc.text(`Manuel Doblado ext 34 int 1`, .3, 1.3);
        doc.text(`Colonia: Centro CDMX`, .3, 1.6);
        doc.text(`Delegacion: Cuauhtemoc, CP: 06020`, .3, 1.9);
        doc.text(`Fecha: ${fecha}`, .3, 2.2);
        doc.text(`Cotizacion # ${numero} / Almacen: ${selectedAreaName}`, .3, 2.5);
        doc.text(`Piezas: ${new Intl.NumberFormat("en-US").format(total_cantidad)} / Cajas ${TC.toFixed(2)}`, .3, 2.8);
        doc.text(`Cliente: ${value.nombre_comercial}`, .3, 3.1);
        doc.text(`Vendedor: ${selectedColaboradorName}`, .3, 3.4);
        doc.setFontSize(12);
        doc.text(`EL PRESENTE ES UNA COTIZACION`, .3, 4);
        doc.text(`---NO ES UNA VENTA---`, 1.3, 4.4);
        doc.autoTable({
          head: [["Articulo", "Cant", "Precio", "Total"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
          },
          startY: 5,
          theme: "plain",
          margin: { left: 0.3, right: 0.7 },
          footStyles: {
            fontSize: 12,
            fillColor: [255,255,255],
            textColor: [0,0,0]
          },
          foot: [
            [
              "",
              { content: 'SubTotal', colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subTotal),
            ],
            [
              "",
              { content: `Comision`, colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(iva),
            ],
            [
              "",
              { content: "Comision TDC", colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(comisionTDC),
            ],
            [
              "",
              { content: "Comision Flete", colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(comisionFlete),
            ],
            [
              "",
              { content: "Total", colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });
        
        doc.autoPrint();
        window.open(doc.output("bloburl"), "_blank");
        // doc.save(`Cotizacion-${numero}.pdf`);
  }



  
  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInv: "",
        cantInv: 0,
        articulos: "",
        articulosNombre: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
        linea:"",
        familia:"",
        marca:""
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);

    let totalKG = values.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) 

    setTotalGeneral(totTemp);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
          i.cajas = event.target.value;
          i.cantidad =
            parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
          i.cantidad = event.target.value;
          i.cajas =
            parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let piezasPorCaja = a.piezasPorCaja;
          let precio = a.venta;
          let idInv = a._id;
          let cantInv = a.cantidad;
          let articulosNombre = a.nombre;
          let linea = a.linea
          let familia = a.familia
          let marca = a.marca
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            idInv,
            cantInv,
            articulosNombre,
            linea,
            familia,
            marca
          );
        }
      });
    }
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    idInv,
    cantInv,
    articulosNombre,
    linea,
    familia,
    marca
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        i.idInv = idInv;
        i.cantInv = cantInv;
        i.articulosNombre = articulosNombre;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.linea = linea
        i.familia = familia
        i.marca = marca
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) 

    setTotalGeneral(totTemp);
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(selectedCliente) {
   if(selectedCliente)
    { setValue(selectedCliente);

    axios
      .get(`${URL_CLIENTES}/${selectedCliente._id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCliente = res.data;

        setCliente(allCliente);
        setSelectedColaborador(allCliente.vendedor[0]._id);
        setSelectedColaboradorName(allCliente.vendedor[0].nombre + " " + allCliente.vendedor[0].apellido);
      })
      .catch((err) => {
        console.log(err);
      });
   
    }
  }


  function jalaColaborador(value) {
    let split = value.split(",");
    let id = split[0];
    let nombre = split[1];
    let apellido = split[2];

    setSelectedColaboradorInput(value);
    setSelectedColaborador(id);
    setSelectedColaboradorName(nombre + " " + apellido);

  }


  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (e * subTotal) / 100 +
      subTotal +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) 
    setTotalGeneral(totTemp);
  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * subTotal) / 100 +
      subTotal +
      parseFloat(e) +
      parseFloat(comisionFlete) 
    setTotalGeneral(totTemp);
  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * subTotal) / 100 +
      subTotal +
      parseFloat(comisionTDC) +
      parseFloat(e) 

    setTotalGeneral(totTemp);
  }


  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsArticulos.includes(event.target.value)) {
        inventarios.map((a) => {
          if (a.idArticulo == event.target.value) {
            let idArticulo = a.idArticulo;
            let piezasPorCaja = a.piezasPorCaja;
            let precio = a.venta;
            let idInv = a._id;
            let cantInv = a.cantidad;
            let articulosNombre = a.nombre;
            let linea = a.linea
            let familia = a.familia
            let marca = a.marca
            handleChangeInputArticulo(
              id,
              idArticulo,
              piezasPorCaja,
              precio,
              idInv,
              cantInv,
              articulosNombre,
              linea,
              familia,
              marca
            );
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo no aparece en tu inventario",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.ventasTiendas ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Cotizacion</h3>
          <Form onSubmit={saveVenta}>
            <Row>

              <Col md={3}>
                <Label className="mr-sm-2">Cliente</Label>
                <Autocomplete
                  size="small"
                  value={value}
                  onChange={(event, selectedCliente) => {
                    jalaCliente(selectedCliente);
                  }}
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.junta}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <React.Fragment>
                      {option.codigo} {option.nombre_comercial}
                    </React.Fragment>
                  )}
                />
              </Col>

              <Col md={1}>
                <Label>Vendedor</Label>
                <Input
                  type="select"
                  value={selectedColaboradorInput}
                  required
                  onChange={(e) => {
                    jalaColaborador(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.apellido > b.apellido ? 1 : -1))
                    .map((a) => {
                      if(user.areas == a.areas[0]._id)
                      return (
                        <option value={[a._id, a.nombre, a.apellido]}>{a.nombre} {a.apellido}</option>
                      );
                    })}
                </Input>
              </Col>
            
              <Col md={1}>
                <Label>Comision TDC</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Comision TDC"
                  value={comisionTDC}
                  required
                  onChange={(e) => {
                    jalaComisionTDC(e.target.value);
                  }}
                />
              </Col>

              <Col md={1}>
                <Label>Comision Flete</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Comision Flete"
                  value={comisionFlete}
                  required
                  onChange={(e) => {
                    jalaComisionFlete(e.target.value);
                  }}
                />
              </Col>

              <Col md={1}>
                <Label>Impuestos %</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  value={selectedImpuesto}
                  onChange={(e) => {
                    calculaImpuesto(e.target.value);
                  }}
                />
              </Col>
             

            </Row>
            <Row>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>

              <Col md={1}>
                <Label>Sub Total</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Sub Total"
                  value={subTotal.toFixed(2)}
                  required
                  disabled
                />
              </Col>

              <Col md={1}>
                <Label>IVA</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="IVA"
                  value={iva.toFixed(2)}
                  required
                  disabled
                />
              </Col>

              <Col md={1}>
                <Label>Total</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Total"
                  value={total_general}
                  required
                  disabled
                />
              </Col>

              <Col md={1}>
                <Label>Piezas</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Piezas"
                  value={total_cantidad}
                  required
                  disabled
                />
              </Col>
             
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Codigo QR</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Inventario</Label>
              </Col>
              {/* <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col> */}
              <Col md={1}>
                <Label className="mr-sm-2">Piezas Por Caja</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosUnicos
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a.idArticulo}>
                              {a.nombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantInv"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cantInv}
                      disabled
                    ></Input>
                  </Col>
                  {/* <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCajas(inputField.id, event)
                      }
                    ></Input>
                  </Col> */}
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="piezasPorCaja"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/CotizacionesTiendas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
                  <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default CotizacionesTiendasCreate;
