import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoVentasTiendas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_VENTAS_TIENDAS = process.env.REACT_APP_URL_ARTICULOS_VENTAS_TIENDAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;

  let date = new Date()
  let hoy = moment(date).format("YYYY-MM-DD"); 

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [totalTotal, setTotalTotal] = useState(0);
  
  const [areas, setAreas] = useState([]);
  const [selectedAreaFiltro, setSelectedAreaFiltro] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");


  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(()=>{
    if(user.areas){
    if(user.areas != AREAS_GENERAL){
    axios
      .get(`${URL_VENTAS_TIENDAS}VentasDiario/${user.areas}/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        // Array para pagination
        let arrayTabla = allVentas
          .sort((a, b) => (a.idVentaTienda > b.idVentaTienda ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.prefijo + a.consecutivo,
              activo: a.is_active,
              fecha: a.fecha,
              colaboradores: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              observaciones: a.observaciones,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              sucursal: a.sucursales[0]._id,
              alias: a.sucursales[0].alias,
              calle: a.sucursales[0].calle,
              numero_ext: a.sucursales[0].numero_ext,
              numero_int: a.sucursales[0].numero_int,
              pais: a.sucursales[0].pais,
              estado: a.sucursales[0].estado,
              ciudad: a.sucursales[0].ciudad,
              delegacion: a.sucursales[0].delegacion,
              colonia: a.sucursales[0].colonia,
              cp: a.sucursales[0].cp,
              paqueteria: a.sucursales[0].paqueteria,
              idAreas: a.areas[0]._id,
              area: a.areas[0].name,
              condPago: a.cond_pago,
              entregado:a.entregado,
              descuento:a.descuento,
              descuentoNumero: (parseFloat(a.subTotal) * parseFloat(a.descuento))/100,
              cortesia: a.cortesia
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setComments(data);

        let activos = data.filter((e) => e.activo == "Si");
        let imp = activos.map((c) => parseFloat(c.total_general));
        let Timp = imp.reduce((t, cajas, index) => t + cajas, 0);
        setTotalTotal(Timp);
        //
      })
      .catch((err) => {
        console.log(err);
      })} else{
        axios
          .get(`${URL_VENTAS_TIENDAS}VentasDiarioGeneral/${selectedFechaInicio}/${selectedFechaFin}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allVentas = response.data;
            // Array para pagination
            let arrayTabla = allVentas
              .sort((a, b) => (a.idVentaTienda > b.idVentaTienda ? 1 : -1))
              .map((a) => {
                return {
                  _id: a._id,
                  numero: a.prefijo + a.consecutivo,
                  activo: a.is_active,
                  fecha: a.fecha,
                  colaboradores: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                  idColaboradores: a.colaboradores[0]._id,
                  observaciones: a.observaciones,
                  subTotal: a.subTotal,
                  iva: a.iva,
                  total_general: a.total_general,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  total_cantidad: a.total_cantidad,
                  comisionTDC: a.comisionTDC,
                  comisionFlete: a.comisionFlete,
                  impuestos: a.impuestos,
                  sucursal: a.sucursales[0]._id,
                  alias: a.sucursales[0].alias,
                  calle: a.sucursales[0].calle,
                  numero_ext: a.sucursales[0].numero_ext,
                  numero_int: a.sucursales[0].numero_int,
                  pais: a.sucursales[0].pais,
                  estado: a.sucursales[0].estado,
                  ciudad: a.sucursales[0].ciudad,
                  delegacion: a.sucursales[0].delegacion,
                  colonia: a.sucursales[0].colonia,
                  cp: a.sucursales[0].cp,
                  paqueteria: a.sucursales[0].paqueteria,
                  idAreas: a.areas[0]._id,
                  area: a.areas[0].name,
                  condPago: a.cond_pago,
                  entregado:a.entregado,
                  descuento:a.descuento,
                  descuentoNumero: (parseFloat(a.subTotal) * parseFloat(a.descuento))/100,
                  cortesia: a.cortesia
                }
              })
              .filter(function (el) {
                return el != null;
              });
    
            let data = Object.values(arrayTabla);
    
            setComments(data);

            let activos = data.filter((e) => e.activo == "Si");
            let imp = activos.map((c) => parseFloat(c.total_general));
            let Timp = imp.reduce((t, cajas, index) => t + cajas, 0);
            setTotalTotal(Timp);
            //
          })
          .catch((err) => {
            console.log(err);
          })}
        }

          axios
          .get(URL_AREAS, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allAreas = res.data;
            setAreas(allAreas);
          })
          .catch((err) => {
            console.log(err);
          });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [user, selectedFechaInicio, selectedFechaFin]);

  const ListaVentas = comments.sort((a, b) =>
    a.idVentaTienda > b.idVentaTienda ? 1 : -1
  );

  function PDFTabla() {
    let TotalTabla = 0
    let TotalPiezas = 0
    const data = ListaVentas.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)&&
        (selectedAreaFiltro == 0 || selectedAreaFiltro == a.idAreas)
      ) {
        if(a.activo =="Si"){
        TotalTabla = TotalTabla + a.total_general
        TotalPiezas = TotalPiezas + a.total_cantidad
        return [
          a.numero,
          a.area,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          a.condPago,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }else{
         return [
          a.numero,
          a.area,
          a.fecha,
          a.colaboradores,
          a.clientes,
          "CANCELADA",
          "NA",
          0,
        ];
      }
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);

    doc.setFontSize(14)
    doc.text(`Listado Ventas`, 15, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 15, 35);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Area",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Cond Pago",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      footStyles: {
            fontSize: 12,
            fillColor: [255,255,255],
            textColor: [0,0,0]
          },
          foot: [
        [
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezas),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTabla),
        ]
      ],
      showFoot: "lastPage",
    });
    doc.save(`Ventas.pdf`);
  }

  function excel() {
    const dataExcel = ListaVentas.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)&&
        (selectedAreaFiltro == 0 || selectedAreaFiltro == a.idAreas)
      ) {
        if(a.activo =="Si"){
        return {
          Numero: a.numero,
          Area: a.area,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          CondPago: a.condPago,
          SubTotal: a.subTotal,
          Total: a.total_general,
        }
      }else{
        return {
          Numero: a.numero,
          Area: a.area,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: "CANCELADA",
          CondPago: "NA",
          SubTotal:0,
          Total: 0,
        } 
      }
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoVentas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoVentas",
        sheetFilter: [
          "Numero",
          "Area",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "CondPago",
          "SubTotal",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Area",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "CondPago",
          "SubTotal",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalTabla = 0
    let TotalPiezas = 0
    const data = ListaVentas.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)&&
        (selectedAreaFiltro == 0 || selectedAreaFiltro == a.idAreas)
      ) {
        if(a.activo =="Si"){
        TotalTabla = TotalTabla + a.total_general
        TotalPiezas = TotalPiezas + a.total_cantidad
        return [
          a.numero,
          a.area,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          a.condPago,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }else{
         return [
          a.numero,
          a.area,
          a.fecha,
          a.colaboradores,
          a.clientes,
          "CANCELADA",
          "NA",
          0,
        ];
      }
      }
    });
    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Ventas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Area",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Cond Pago",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      footStyles: {
            fontSize: 12,
            fillColor: [255,255,255],
            textColor: [0,0,0]
          },
          foot: [
        [
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezas),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTabla),
        ]
      ]
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ventas",
          email: mailTo,
          fileName: "ListadoVentas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ventas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

 

  async function PDFOC(
    fecha,
    colaboradores,
    idClientes,
    clientes,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    idPDFOC,
    comisionTDC,
    comisionFlete,
    impuestos,
    paqueteria,
    alias,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    area,
    descuento,
    descuentoNumero,
    cortesia
  ) {
    await axios
      .get(`${URL_ARTICULOS_VENTAS_TIENDAS}/ventasTiendas/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosVenta = response.data;
        let arrayTabla = allArticulosVenta
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].nombre + " " + a.articulos[0].lineas[0].name + " " + a.articulos[0].familias[0].name + " " + a.articulos[0].marcas[0].name,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let cajas = dataFinal.map((c) => parseFloat(c.cajas));
        let TC = cajas.reduce((t, total, index) => t + total, 0);

        let LA = (dataFinal.length)*1.5
        let largoTotal = LA + 4 + 7

        let totalParcial = 0;

        let subTotalTabla = 0
        let total_general_tabla = 0
        let iva_tabla = 0 
        let impuesto_tabla = impuestos

        const data = dataFinal.map((a) => {
          totalParcial = a.cantidad * a.precio;
          subTotalTabla = subTotalTabla + totalParcial
          return [
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        iva_tabla = subTotalTabla * impuesto_tabla/100
        total_general_tabla = subTotalTabla + comisionTDC + comisionFlete + iva_tabla
        const doc = new jsPDF("p", "cm", [8,largoTotal], true);
        var base64 = qrcode.toDataURL(idPDFOC);
        var img3 = new Image();
        img3.src = base64;

        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        
        doc.addImage(img2, "png", 5,1,2,1);

        doc.setFontSize(6);
        doc.text(`Chamap SA de CV / RFC: CAM210616LD9`, .3, 1);
        doc.text(`Manuel Doblado ext 34 int 1`, .3, 1.3);
        doc.text(`Colonia: Centro CDMX`, .3, 1.6);
        doc.text(`Delegacion: Cuauhtemoc, CP: 06020`, .3, 1.9);
        doc.text(`Fecha: ${fecha}`, .3, 2.2);
        doc.text(`Nota # ${numero} / Almacen: ${area}`, .3, 2.5);
        doc.text(`Piezas: ${new Intl.NumberFormat("en-US").format(TK)} / Cajas ${TC.toFixed(2)}`, .3, 2.8);
        doc.text(`Cliente: ${clientes}`, .3, 3.1);
        doc.text(`Vendedor: ${colaboradores}`, .3, 3.4);
        doc.autoTable({
          head: [
            [
              "Articulo",
              "Cant",
              "Precio",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
        },
          startY: 3.8,
          theme:"plain",
          margin:{left:0.3, right:0.7},
          footStyles: {
            fontSize: 12,
            fillColor: [255,255,255],
            textColor: [0,0,0]
          },
          foot: [
            [
              "",
              { content: 'SubTotal', colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subTotalTabla),
            ],
            [
              "",
              { content: `Descuento ${descuento}%`, colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(descuentoNumero),
            ],
            [
              "",
              { content: `Comision`, colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(iva_tabla),
            ],
            [
              "",
              { content: "Comision TDC", colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(comisionTDC),
            ],
            [
              "",
              { content: "Comision Flete", colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(comisionFlete),
            ],
            [
              "",
              { content: "Total", colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_general_tabla),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.addImage(img3, "png", .3, doc.autoTable.previous.finalY -3.5, 3, 3);

        doc.save(`Nota-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }



  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Tienda", field: "area", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "Cantidad", field: "total_cantidad", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Cond Pago", field: "condPago", sortable: true },
    { name: "Entregado", field: "entregado", sortable: true },
    { name: "Recibo", field: "recibo", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search) ||
          comment.condPago.toLowerCase().includes(search.toLowerCase()) 
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if (selectedAreaFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idAreas.includes(selectedAreaFiltro)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    selectedFechaInicio,
    selectedFechaFin,
    selectedAreaFiltro
  ]);

  function cancelarNota(idNota) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara la Nota!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .get(`${URL_VENTAS_TIENDAS}Cancel/${idNota}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }

  let tot = 0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.listadoVentasTiendas ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.ventasTiendas ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/VentasTiendasCreate"
                >
                  Nuevo Venta
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Venta
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ventas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte de Ventas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

          
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedAreaFiltro}
                onChange={(e) => {
                  setSelectedAreaFiltro(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
              </td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                  if(a.activo == "Si"){
                    tot = tot + a.total_general
                  return (
                    <tr>
                      {/* <td>{a.activo}</td> */}
                      <td>{a.numero}</td>
                      <td>{a.area}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.colaboradores}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.total_cantidad)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general)}</td>
                      <td>{a.condPago}</td>
                      <td>{a.entregado}</td>
                      <td>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.colaboradores,
                                  a.idClientes,
                                  a.clientes,
                                  a.total_cantidad,
                                  a.total_general,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.comisionTDC,
                                  a.comisionFlete,
                                  a.impuestos,
                                  a.paqueteria,
                                  a.alias,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.area,
                                  a.descuento,
                                  a.descuentoNumero,
                                  a.cortesia
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) => cancelarNota(a._id)}
                            >
                              <i class="fas fa-ban"></i>
                            </Button>
                      </td>
                    
                    </tr>
                  );
                }else{
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c"}}>
                      <td>{a.numero}</td>
                      <td>{a.area}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.colaboradores}</td>
                      <td></td>
                      <td></td>
                      <td>{a.condPago}</td>
                      <td>{a.entregado}</td>
                      <td>CANCELADA </td>
                  </tr>
                  )
                }
              })}
              
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(tot)}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotal)}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

     
      {loader}
    </>
  );
}

export default ListadoVentasTiendas;
