import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import TablaProveedores from "./TablaProveedores";
import { Button, Row, Table } from "reactstrap";
import Header from "../../../layout/Header/Header";
import axios from "axios";


function ListadoProveedores() {
  const { user } = useContext(AuthContext);
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const [proveedores, setProveedores] = useState([]);

  useEffect(() => {
    axios
      .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  return (
    <>     
     <Header />
    <br />
    <br />
    {user.menu_proveedores  ?(
        <div className="card col-12">
          <Row>
              {user.proveedores_create  ?(
              <Button size="sm" type="submit" className="btn btn-success" href="/ProveedoresCreate">Nuevo Proveedor</Button>
              ):(
                <Button size="sm" type="submit" className="btn btn-success" disabled>Nuevo Proveedor</Button>
              )}
              <Button
              size="sm"
              href="/MenuCompras"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
            </Row>
                <h3 align="center">Proveedores</h3>
                <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                    <tr>
                      <th className="tituloTabla">
                        Nombre Comercial
                      </th>
                      <th className="tituloTabla">
                        Razon Social
                      </th>
                      <th className="tituloTabla">
                        RFC
                      </th>
                      <th className="tituloTabla">
                        Contacto
                      </th>
                      <th className="tituloTabla">
                        Telefono
                      </th>
                      <th className="tituloTabla">
                        Email
                      </th>
                      {/* <th className="tituloTabla">
                        Cargos
                      </th>
                      <th className="tituloTabla">
                        Abonos
                      </th>
                      <th className="tituloTabla">
                        Saldo
                      </th> */}
                      {/* <th className="tituloTabla">
                        Grupo
                      </th> */}
                      <th className="tituloTabla">
                        Editar
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {proveedores.map((p) => {
                      return (
                        <tr>
                          <TablaProveedores
                            nomcom={p.nombre_comercial}
                            razsoc={p.razon_social}
                            RFC={p.RFC}
                            contacto={p.contacto}
                            telefono={p.telefono}
                            email={p.email}
                            _id={p._id} is_active={p.is_active}
                            calle={p.calle}
                            numero_ext={p.numero_ext}
                            numero_int={p.numero_int}
                            delegacion={p.delegacion}
                            estado={p.estado}
                            pais={p.pais}
                            cp={p.cp}
                            colonia={p.colonia}
                            moneda={p.moneda}
                            anticipo={p.anticipo}
                            tiempoEntrega={p.tiempoEntrega}
                            ciudad={p.ciudad}
                            idProveedor={p._id}
                            cargos={p.cargos}
                            abonos={p.abonos}
                            saldo={p.saldo}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
        </div>
      ): undefined } 
    </>
  );
}

export default ListadoProveedores;
