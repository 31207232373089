import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import Switch from "@material-ui/core/Switch";
import moment from "moment";

function ListadoReabasto() {
  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [selectedCantidad, setSelectedCantidad] = useState();
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [marcas, setMarcas] = useState([]);
  const [selectedMarca, setSelectedMarca] = useState("");
  const [unidades, setUnidades] = useState([]);
  const [selectedUnidad, setSelectedUnidad] = useState("");
  const [lineas, setLineas] = useState([]);
  const [selectedLinea, setSelectedLinea] = useState("");
  const [familias, setFamilias] = useState([]);
  const [selectedFamilia, setSelectedFamilia] = useState("");

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [comments2, setComments2] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [validaBoton, setValidaBoton] = useState(true);
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [filtersVisible, setFiltersVisible] = useState(false);


  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_INVENTARIOS}Reabasto`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        // let array = allDisponible.map((a)=>{
        //   if(a.inventario != 0 || a.pedido !=0 || a.calidad !=0 || a.disponible !=0){
        //   return a
        //   }
        // }).filter(function (el) {
        //   return el != null;
        // })
        setComments(allDisponible);

        let unicosArt = allDisponible.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idArticulo === value.idArticulo)
        );
        setArticulos(unicosArt);

        let unicosMar = allDisponible.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idMarca === value.idMarca)
        );
        setMarcas(unicosMar);

        let unicosUni = allDisponible.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idUnidad === value.idUnidad)
        );
        setUnidades(unicosUni);

        let unicosLinea = allDisponible.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idLinea === value.idLinea)
        );
        setLineas(unicosLinea);

        let unicosFamilia = allDisponible.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idFamilia === value.idFamilia)
        );
        setFamilias(unicosFamilia);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${URL_PROVEEDORES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const headers = [
    { name: "SKU", field: "SKU", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Tiempo Entrega", field: "tiempoEntrega", sortable: true },
    { name: "Inventario", field: "inventario", sortable: true },
    { name: "Calidad", field: "calidad", sortable: true },
    { name: "Pedido", field: "cantidadOC", sortable: true },
    { name: "Pend Recibir", field: "porRecibir", sortable: true },
    { name: "Total Inv", field: "totalInv", sortable: false },
    { name: "Pend Surtir", field: "pedido", sortable: true },
    { name: "Venta 15 Dias x 2", field: "ventaDias", sortable: true },
    { name: "Ingreso Prom", field: "precioPromedio", sortable: true },
    { name: "Costo Uni", field: "costoUnitario", sortable: true },
    { name: "Utilidad $", field: "utilidadDinero", sortable: false },
    { name: "Margen", field: "porcentajeUtilidad", sortable: false },
    { name: "Cant OC", field: "cantidad", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.SKU.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.inventario.toString().includes(search) ||
          comment.calidad.toString().includes(search) ||
          comment.cantidadOC.toString().includes(search) ||
          comment.porRecibir.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.traspaso.toString().includes(search) ||
          comment.disponible.toString().includes(search) ||
          comment.ventaDias.toString().includes(search) ||
          comment.precioPromedio.toString().includes(search) ||
          comment.cantidad.toString().includes(search) ||
          comment.tiempoEntrega.toString().includes(search) ||
          comment.costoUnitario.toString().includes(search)
      );
    }

    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }
    if (selectedCantidad) {
      computedComments = computedComments.filter(
        (e) => e.cantidad >= selectedCantidad
      );
    }

    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }

    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.proveedor.includes(selectedProveedor)
      );
    }

    // if (selectedMarca) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idMarca.includes(selectedMarca)
    //   );
    // }

    // if (selectedUnidad) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idUnidad.includes(selectedUnidad)
    //   );
    // }
    if (selectedUnidad.length > 0) {
      if (selectedUnidad) {
        computedComments = computedComments.filter((e) =>
          selectedUnidad.some((a) => e.idUnidad && e.idUnidad.includes(a))
        );
      }
    }

    if (selectedMarca.length > 0) {
      if (selectedMarca) {
        computedComments = computedComments.filter((e) =>
          selectedMarca.some((a) => e.idMarca && e.idMarca.includes(a))
        );
      }
    }

    if (selectedLinea.length > 0) {
      if (selectedLinea) {
        computedComments = computedComments.filter((e) =>
          selectedLinea.some((a) => e.idLinea && e.idLinea.includes(a))
        );
      }
    }


    if (selectedFamilia.length > 0) {
      if (selectedFamilia) {
        computedComments = computedComments.filter((e) =>
          selectedFamilia.some((a) => e.idFamilia && e.idFamilia.includes(a))
        );
      }
    }
    // if (selectedLinea) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idLinea.includes(selectedLinea)
    //   );
    // }

    // if (selectedFamilia) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idFamilia.includes(selectedFamilia)
    //   );
    // }

    setTotalItems(computedComments.length);

    setComments2(computedComments);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "inventario" &&
      sorting.field != "calidad" &&
      sorting.field != "porRecibir" &&
      sorting.field != "cantidadOC" &&
      sorting.field != "pedido" &&
      sorting.field != "traspaso" &&
      sorting.field != "disponible" &&
      sorting.field != "ventaDias" &&
      sorting.field != "precioPromedio" &&
      sorting.field != "porcentajeUtilidad" &&
      sorting.field != "costoUnitario" &&
      sorting.field != "tiempoEntrega" &&
      sorting.field != "cantidad"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "inventario" ||
        sorting.field == "calidad" ||
        sorting.field == "porRecibir" ||
        sorting.field == "cantidadOC" ||
        sorting.field == "pedido" ||
        sorting.field == "traspaso" ||
        sorting.field == "disponible" ||
        sorting.field == "ventaDias" ||
        sorting.field == "precioPromedio" ||
        sorting.field == "porcentajeUtilidad" ||
        sorting.field == "cantidad" ||
        sorting.field == "tiempoEntrega" ||
        sorting.field == "costoUnitario")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "inventario" ||
        sorting.field == "calidad" ||
        sorting.field == "porRecibir" ||
        sorting.field == "cantidadOC" ||
        sorting.field == "pedido" ||
        sorting.field == "traspaso" ||
        sorting.field == "disponible" ||
        sorting.field == "ventaDias" ||
        sorting.field == "precioPromedio" ||
        sorting.field == "porcentajeUtilidad" ||
        sorting.field == "cantidad" ||
        sorting.field == "tiempoEntrega" ||
        sorting.field == "costoUnitario")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedArticulo,
    selectedCantidad,
    activo,
    selectedFamilia,
    selectedLinea,
    selectedMarca,
    selectedUnidad,
    selectedProveedor
  ]);

  const handleCheckboxChangeUnidad = (e) => {
    const { value, checked } = e.target;
    // Actualiza el estado de los artículos seleccionados
    if (checked) {
      setSelectedUnidad([...selectedUnidad, value]);
    } else {
      setSelectedUnidad(selectedUnidad.filter((item) => item !== value));
    }
  };

  const handleCheckboxChangeMarca = (e) => {
    const { value, checked } = e.target;
    // Actualiza el estado de los artículos seleccionados
    if (checked) {
      setSelectedMarca([...selectedMarca, value]);
    } else {
      setSelectedMarca(selectedMarca.filter((item) => item !== value));
    }
  };

  const handleCheckboxChangeLinea = (e) => {
    const { value, checked } = e.target;
    // Actualiza el estado de los artículos seleccionados
    if (checked) {
      setSelectedLinea([...selectedLinea, value]);
    } else {
      setSelectedLinea(selectedLinea.filter((item) => item !== value));
    }
  };

  const handleCheckboxChangeFamilia = (e) => {
    const { value, checked } = e.target;
    // Actualiza el estado de los artículos seleccionados
    if (checked) {
      setSelectedFamilia([...selectedFamilia, value]);
    } else {
      setSelectedFamilia(selectedFamilia.filter((item) => item !== value));
    }
  };


// 1. Agrupar los artículos por proveedor
const articulosPorProveedor = {};
comments.forEach((item) => {
    const proveedorId = item.idProveedor;
    if (!articulosPorProveedor[proveedorId]) {
        articulosPorProveedor[proveedorId] = [];
    }
    articulosPorProveedor[proveedorId].push(item);
});

// 2. Generar las órdenes de compra
const ordenesDeCompra = [];
for (const proveedorId in articulosPorProveedor) {
    const articulosDelProveedor = articulosPorProveedor[proveedorId];

    // Filtrar los artículos con cantidad mayor a 0
    const articulosConCantidad = articulosDelProveedor.filter((item) => item.cantidad > 0);

    // Si hay artículos con cantidad mayor a 0 para este proveedor, generar la orden de compra
    if (articulosConCantidad.length > 0) {
        // Calcular el total de cantidad por proveedor
        const totalCantidadProveedor = articulosConCantidad.reduce((total, item) => total + item.cantidad, 0);
        
        // Obtener la moneda del primer artículo del proveedor (suponiendo que todos los artículos del proveedor tienen la misma moneda)
        const monedaProv = articulosDelProveedor.length > 0 ? articulosDelProveedor[0].monedaProv : null;

        // Crear la orden de compra para este proveedor
        const ordenDeCompra = {
            proveedor: articulosDelProveedor[0].idProveedor, // Suponiendo que todos los artículos de un proveedor tienen el mismo proveedor
            total_cantidad_proveedor: totalCantidadProveedor, // Agregar el total de cantidad por proveedor
            monedaProv: monedaProv, // Agregar la moneda del proveedor
            articulos: articulosConCantidad.map((item) => ({ id: item.idArticulo, nombre: item.nombre, cantidad: item.cantidad }))
        };
        // Agregar la orden de compra al array de órdenes de compra
        ordenesDeCompra.push(ordenDeCompra);
    }
}

// Ahora 'ordenesDeCompra' contiene las órdenes de compra generadas para cada proveedor, con el total de cantidad por proveedor
// console.log(ordenesDeCompra);

// Obtener el total de artículos en el array de órdenes de compra
const totalArticulos = ordenesDeCompra.reduce((total, orden) => total + orden.articulos.length, 0);


const saveOrdenCompra = async (event) => {
  event.preventDefault();
  // setValidaBoton(false);
  // toggleProgreso();

  let totArt = totalArticulos;


  try {
    ordenesDeCompra.map((orden) => {
      axios
        .post(
          URL_ORDENES_COMPRA,
          {
            fecha: hoy,
            proveedores: orden.proveedor,
            plazo: 0,
            fecha_compromiso: hoy,
            total_general: 0,
            total_cantidad: orden.total_cantidad_proveedor,
            observaciones: "NA",
            embarcado: 0,
            moneda: orden.monedaProv
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          orden.articulos.map((articulo) => {
            if (parseFloat(articulo.cantidad) > 0) { // Verificar si la cantidad es mayor a 0
              axios
                .post(
                  URL_ARTICULOS_OC,
                  {
                    ordenesCompra: data.data._id,
                    articulos: articulo.id,
                    cantidad: parseFloat(articulo.cantidad),
                    embarcado: 0,
                    pendiente_embarcar: parseFloat(articulo.cantidad),
                    precio: 0,
                    total: 0,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totArt--;
                  if (totArt === 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Orden de Compra",
                          detalle: `${selectedProveedor} 0`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 3000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totArt--;
              if (totArt === 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Orden de Compra",
                      detalle: `${selectedProveedor} 0`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
    });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
    console.log(error);
  }
};

  // let totalDisponible = 0;
  // let totalPedido = 0;
  // let totalTraspaso = 0;
  // let totalInventario = 0;
  // let totalCalidad = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_disponible ? (
        <div className="card col-12">
          <Row>
            <Col md={6}>
              <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>


              <Col sm={6}>
                  {validaBoton ? (
                    <Button id="logoutBoton" color="info" onClick={saveOrdenCompra} size="sm">
                      Generar OC
                    </Button>
                  ) : (
                    <Button id="logoutBoton" color="info" onClick={saveOrdenCompra} size="sm" disabled>
                      Generar OC
                    </Button>
                  )}
              </Col>
          </Row>

          <div>
      <h3 align="center">Reabasto</h3>

      <div className="row">

        <div className="col-md-12">
          <Button color="info" size="sm" onClick={() => setFiltersVisible(!filtersVisible)}>
            {<i class="fas fa-filter"></i>}
          </Button>
        </div>

        {filtersVisible && (
          <>
            <div className="col-md-2">
              <ul>
                <Label className="negrita" align="center">
                  UNIDAD NEGOCIO
                </Label>
                {unidades.map((a) => (
                  <li key={a.idUnidad}>
                    <label>
                      <Switch
                        value={a.idUnidad}
                        checked={selectedUnidad.includes(a.idUnidad)}
                        onChange={(e) => handleCheckboxChangeUnidad(e, a.idUnidad)}
                        color="primary"
                      />
                      {a.unidad}
                    </label>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-md-2">
              <ul>
                <Label className="negrita" align="center">
                  MARCA
                </Label>
                {marcas.map((a) => (
                  <li key={a.idMarca}>
                    <label>
                      <Switch
                        value={a.idMarca}
                        checked={selectedMarca.includes(a.idMarca)}
                        onChange={(e) => handleCheckboxChangeMarca(e, a.idMarca)}
                        color="primary"
                      />
                      {a.marca}
                    </label>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-md-2">
              <ul>
                <Label className="negrita" align="center">
                  LINEA
                </Label>
                {lineas.map((a) => (
                  <li key={a.idLinea}>
                    <label>
                      <Switch
                        value={a.idLinea}
                        checked={selectedLinea.includes(a.idLinea)}
                        onChange={(e) => handleCheckboxChangeLinea(e, a.idLinea)}
                        color="primary"
                      />
                      {a.linea}
                    </label>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-md-2">
              <ul>
                <Label className="negrita" align="center">
                  FAMILIA
                </Label>
                {familias.map((a) => (
                  <li key={a.idFamilia}>
                    <label>
                      <Switch
                        value={a.idFamilia}
                        checked={selectedFamilia.includes(a.idFamilia)}
                        onChange={(e) => handleCheckboxChangeFamilia(e, a.idFamilia)}
                        color="primary"
                      />
                      {a.familia}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}

        <div className="col-md-2"></div>
      </div>
    </div>
<br />
    <div className="col-md-2">
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
          <div className="table-wrapper">
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.SKU > b.SKU ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idArticulo}>{a.SKU}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                {/* <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idArticulo}>{a.nombre}</option>;
                    })}
                </Input> */}
                              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedProveedor}
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a.idProveedor}>
                          {a.nombre_comercial}
                        </option>
                      );
                    })}
                </Input>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCantidad}
                  onChange={(e) => {
                    setSelectedCantidad(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Todos</option>
                  <option value="1">Con Cantidad</option>
                </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                // totalInventario = totalInventario + a.inventario;
                // totalCalidad = totalCalidad + a.calidad;
                // totalPedido = totalPedido + a.pedido;
                // totalTraspaso = totalTraspaso + a.traspaso;
                // totalDisponible = totalDisponible + a.disponible;
                {
                  return (
                    <tr key={a.idArticulo}>
                      <td>{a.SKU}</td>
                      <td>{a.nombre}</td>
                      <td>{a.proveedor}</td>
                      <td>{a.tiempoEntrega}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.inventario)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.calidad)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.cantidadOC)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.porRecibir)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.inventario + a.calidad + a.cantidadOC + a.porRecibir)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.pedido)}</td>
                      {/* <td>{new Intl.NumberFormat("en-US").format(a.traspaso)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.disponible)}</td> */}
                      <td>{new Intl.NumberFormat("en-US").format(a.ventaDias)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precioPromedio)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoUnitario)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precioPromedio - a.costoUnitario)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(a.porcentajeUtilidad)}</td>
                      <td style={{ minWidth: '120px' }}>
                        <Input
                          type="number"
                          value={a.cantidad}
                          onChange={(e) => {
                            const newComments = comments.map((item) => {
                              if (item.idArticulo === a.idArticulo) {
                                return { ...item, cantidad: parseInt(e.target.value) };
                              }
                              return item;
                            });
                            setComments(newComments);
                          }}
                        />
                      </td>




                    </tr>
                  );
                }
              })}
              {/* <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalInventario)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalCalidad)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPedido)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalTraspaso)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalDisponible)}
                </td>
                <td></td>
                <td></td>
              </tr> */}
            </tbody>
          </Table>
          </div>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>



          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}

      {loader}
    </>
  );
}

export default ListadoReabasto;
