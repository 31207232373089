import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import { makeStyles } from "@material-ui/core/styles";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function VentaDirectaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  // const AREAS_MIXCALCO = process.env.REACT_APP_AREAS_MIXCALCO;
  const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  // const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  // const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_SUCURSALES = process.env.REACT_APP_URL_SUCURSALES;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  // const [idsArticulos, setIdsArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [sucursales, setSucursales] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState([]);
  const [sucursal, setSucursal] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorName, setSelectedColaboradorName] = useState("");
  const [value, setValue] = useState("");
  const [articulosUnicos, setArticulosUnicos] = useState([]);
  const [articulosCatalogo, setArticulosCatalogo] = useState([]);
  const [totalCaptura, setTotalCaptura] = useState(1);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [inventarios, setInventarios] = useState([]);

  const [cliente, setCliente] = useState("");
  const [condPago, setCondPago] = useState("Contado");
  const [departamentoCliente, setDepartamentoCliente] = useState("");
  const [paqueterias, setPaqueterias] = useState([]);
  const [paqueteria, setPaqueteria] = useState("NA");
  const [idPaqueteria, setIdPaqueteria] = useState("650b273bc9855e3f883e8f5d");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(AREAS_CEDIS);
  const [selectedAreaName, setSelectedAreaName] = useState("CEDIS");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [total_cajas, setTotalCajas] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);

  // const [descuento, setDescuento] = useState(0);
  // const [descuentoNumero, setDescuentoNumero] = useState(0);

  const [listaPrecios, setListaPrecios] = useState(1);

  const [bancos, setBancos] = useState([]);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());

  const [pago, setPago] = useState();
  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [fechaTransfer, setFechaTransfer] = useState("NA");
  const [referencia, setReferencia] = useState("NA");
  const [destino, setDestino] = useState("NA");
  const [terminal, setTerminal] = useState("Tarjeta");
  const [saldo, setSaldo] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      precioOriginal: 0,
      total: 0,
      articulosNombre: "",
      observacionesArticulo: "NA",
      idInv: "",
      costoUnitario: 0,
      cantInv: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  const [mailAutEnviado, setMailAutEnviado] = useState(false);
  const [randomCambiaPrecio, setRandomCambiaPrecio] = useState(
    (Math.random() + 1).toString(36).substring(2)
  );

  useMemo(() => {
    axios
      .get(`${URL_ARTICULOS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosCat = res.data;
        setArticulosCatalogo(allArticulosCat);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .get(URL_PAQUETERIAS, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     const allPaqueterias = res.data;
    //     setPaqueterias(allPaqueterias);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

    if (selectedArea) {
      axios
        .get(`${URL_INVENTARIOS}AcumuladoAlmacen/${selectedArea}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allDisponible = response.data;
          setInventarios(allDisponible);

          setArticulos(allDisponible);
          // let result = allDisponible.map((a) => a.idArticulo);
          // setIdsArticulos(result);

          let unicos = allDisponible.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.idArticulo === value.idArticulo)
          );
          setArticulosUnicos(unicos);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

    // if(selectedCliente == "650a334ab99f0b3555a8cf16"){
    //   axios
    //   .get(`${URL_CLIENTES}/${selectedCliente}`, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     const allCliente = res.data;
    //     setCliente(allCliente);
    //     // setDescuento(allCliente.descuento);
    //     setListaPrecios(allCliente.listaPrecios);
    //     setSelectedColaborador(allCliente.vendedor[0]._id);
    //     setSelectedColaboradorName(allCliente.vendedor[0].nombre + " " + allCliente.vendedor[0].apellido);
    //     setDepartamentoCliente(allCliente.departamentosClientes[0]._id);
    //     setCondPago(allCliente.forma_pago);
    //     if(allCliente.forma_pago == "Credito"){
    //       setPago(false)
    //     }else setPago(true)
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    // }
  }, [selectedArea]);

  const savePedido = async (event) => {
    event.preventDefault();
    let cantidadIF = inputFields.length;
    let contador = 0;
    inputFields.map(async (a) => {
      if (a.cantInv >= a.cantidad) {
        return (contador = contador + 1);
      }
    });

    if (contador == cantidadIF) {
      if (selectedCliente != "" && selectedArea != "") {
        setValidaBoton(false);
        toggleProgreso();
        let totalPedido = inputFields.length;
        try {
          await axios
            .post(
              URL_PEDIDOS,
              {
                fecha,
                clientes: selectedCliente,
                departamentosClientes: departamentoCliente,
                colaboradores: selectedColaborador,
                total_general: parseFloat(total_general).toFixed(2),
                observaciones,
                total_cantidad,
                paqueterias: "665e148c225a1c1e3f506999",
                areas: selectedArea,
                sucursales: sucursal,
                comisionTDC,
                comisionFlete,
                subTotal: parseFloat(subTotal).toFixed(2),
                iva: parseFloat(iva).toFixed(2),
                impuestos: selectedImpuesto,
                surtido: "Si",
                autorizadoApartado: "Si",
                autorizadoSurtido: "Si",
                editado: "No",
                fechaModificado: fecha,
                creado: user.id,
                fecha_estimada: fecha,
                fecha_entrega: fecha,
                entregaInsumos: "No",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(async (dataPedido) => {
              await axios
                .post(
                  URL_SURTIDOS,
                  {
                    fecha,
                    pedidos: dataPedido.data._id,
                    observaciones,
                    user: user.id,
                    clientes: selectedCliente,
                    areas: selectedArea,
                    total_general: parseFloat(total_general).toFixed(2),
                    saldo: parseFloat(saldo).toFixed(2),
                    subTotal: parseFloat(subTotal).toFixed(2),
                    iva: parseFloat(iva).toFixed(2),
                    impuestos: selectedImpuesto,
                    is_active: "Si",
                    entregado: "Si",
                    paqueterias: "665e148c225a1c1e3f506999",
                    colaboradores: selectedColaborador,
                    comisionPagada: "No",
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then((dataSurtido) => {
                  // PDFOCSurtido(
                  //   dataSurtido.data.idSurtido,
                  //   dataSurtido.data.updatedAt,
                  //   dataPedido.data.idPedido
                  // );

                  inputFields.map((a) => {
                    if (a.articulos != "" && a.cantidad != 0) {
                      axios
                        .post(
                          URL_ARTICULOS_PEDIDO,
                          {
                            pedidos: dataPedido.data._id,
                            articulos: a.articulos,
                            cantidad: parseFloat(a.cantidad),
                            cantidadProcesos: 0,
                            pendiente_surtir: parseFloat(a.cantidad),
                            surtido: 0,
                            precio: parseFloat(a.precio).toFixed(2),
                            total: parseFloat(a.total).toFixed(2),
                            cliente: "NA",
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then((dataArtPed) => {
                          axios
                            .post(
                              `${URL_ARTICULOS_SURTIDOS}/ventaDirecta`,
                              {
                                fecha,
                                surtidos: dataSurtido.data._id,
                                inventarios: a.idInv,
                                cantidad: parseFloat(a.cantidad),
                                articulos: a.articulos,
                                pedidos: dataPedido.data._id,
                                articulosPedido: dataArtPed.data._id,
                                costoUnitario: a.costoUnitario,
                                clientes: selectedCliente,
                                areas: selectedArea,
                                precio: parseFloat(a.precio).toFixed(2),
                                paqueterias: "665e148c225a1c1e3f506999",
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )

                            .then((data) => {
                              totalPedido = totalPedido - 1;
                              if (totalPedido == 0) {
                                axios
                                  .post(
                                    URL_LOGS,
                                    {
                                      tipo: "Crear Pedido",
                                      detalle: `${value._id} ${total_general}`,
                                      user: user.id,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer: ${localStorage.getItem(
                                          "app_token"
                                        )}`,
                                      },
                                    }
                                  )
                                  .then(() => {
                                    Swal.fire(
                                      "Good job!",
                                      "Creado con exito",
                                      `success`
                                    );
                                    setTimeout(() => {
                                      window.location.reload();
                                    }, 1000);
                                  });
                              }
                            })
                            .catch((error) => {
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                // footer: `${error.response.data}`,
                              });
                              console.log(error);
                              setValidaBoton(true);
                            });
                        });
                    } else {
                      totalPedido = totalPedido - 1;
                      if (totalPedido == 0) {
                        axios
                          .post(
                            URL_LOGS,
                            {
                              tipo: "Crear Pedido",
                              detalle: `${value._id} ${total_general}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then(() => {
                            Swal.fire(
                              "Good job!",
                              "Creado con exito",
                              `success`
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 1000);
                          });
                      }
                    }
                  });
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
              setValidaBoton(true);
            });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Faltan datos, favor de revisar",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No puedes vender sin inventario",
        showConfirmButton: false,
      });
    }
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        precioOriginal: 0,
        total: 0,
        articulosNombre: "",
        observacionesArticulo: "NA",
        idInv: "",
        costoUnitario: 0,
        cantInv: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let totalCajas = values.map((c) => parseFloat(c.cajas));
    let TCajas = totalCajas.reduce((t, total, index) => t + total, 0);
    setTotalCajas(TCajas);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
  };

  const handleChangeInputObservaciones = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.observacionesArticulo = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputPrecio = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[name] = value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (i.cantInv >= event.target.value) {
          i.cantidad = parseFloat(event.target.value);
          i.cajas = (
            parseFloat(event.target.value) / parseFloat(i.piezasPorCaja)
          ).toFixed(2);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, selectedArticulo) {
    // if (
    //   inputFields.filter((e) => e.articulos == selectedArticulo.idArticulo).length > 0
    // ) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "El modelo ya fue capturado!",
    //     showConfirmButton: false,
    //   });
    //   const newInputFields = inputFields.map((i) => {
    //     if (id === i.id) {
    //       i.articulosNombre = "";
    //       i.articulos = "";
    //     }
    //     return i;
    //   });
    //   setInputFields(newInputFields);
    // } else {
    // articulos.map((a) => {
    // if (a._id == selectedArticulo._id) {
    let idArticulo = selectedArticulo.idArticulo;
    let piezasPorCaja = selectedArticulo.piezasPorCaja;
    let articulosNombre =
      selectedArticulo.SKU + " " + selectedArticulo.nombre;
    // let idInv = selectedArticulo.idInv;
    let costoUnitario = selectedArticulo.costoUnitario;
    let precio = selectedArticulo.venta;
    let precioOriginal = selectedArticulo.venta;
    handleChangeInputArticulo(
      id,
      idArticulo,
      piezasPorCaja,
      precio,
      precioOriginal,
      articulosNombre,
      // idInv,
      costoUnitario
    );
    // }
    // });
    // }
  }

  function BuscaLote(id, idInventario) {
    inventarios.map((a) => {
      if (a._id == idInventario) {
        let cantInv = parseFloat(a.cantidad);
        let idInv = a._id;
        let costoUnitario = a.costoUnitario
        handleChangeInputArticuloLote(id, cantInv, idInv, costoUnitario);
      }
    });
  }

  const handleChangeInputArticuloLote = (id, cantInv, idInv, costoUnitario) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.costoUnitario = costoUnitario;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    precioOriginal,
    articulosNombre,
    // idInv,
    costoUnitario
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.articulosNombre = articulosNombre;
        i.precioOriginal = precioOriginal;
        i.costoUnitario = costoUnitario;
        i.idInv = "";
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    // handleAddFields();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let totalCajas = inputFields.map((c) => parseFloat(c.cajas));
    let TCajas = totalCajas.reduce((t, total, index) => t + total, 0);
    setTotalCajas(TCajas);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);

    setSaldo(totTemp - efectivo - tdc - transfer);
  }

  function calculaDescuento(e) {
    setDescuento(e);
    let temp1 = (subTotal * e) / 100;

    setDescuentoNumero(temp1);

    let temp = subTotal - temp1;

    let totTemp = temp + parseFloat(comisionTDC) + parseFloat(comisionFlete);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer);
  }

  const options = articulosUnicos.map((option) => {
    const junta = option.SKU + option.nombre;
    const firstLetter = option.SKU[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(cliente) {
    // setInputFields([
    //   {
    //     id: uuidv4(),
    //     articulos: "",
    //     piezasPorCaja: 0,
    //     cajas: 0,
    //     cantidad: 0,
    //     precio: 0,
    //     precioOriginal: 0,
    //     total: 0,
    //     articulosNombre: "",
    //     observacionesArticulo: "NA",
    //     idInv: "",
    //     costoUnitario:0
    //   },
    // ]);

    setSelectedCliente(cliente);

    axios
      .get(`${URL_CLIENTES}/${cliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCliente = res.data;
        setCliente(allCliente);
        setSelectedColaborador(allCliente.vendedor[0]._id);
        setSelectedColaboradorName(
          allCliente.vendedor[0].nombre + " " + allCliente.vendedor[0].apellido
        );
        setDepartamentoCliente(allCliente.departamentosClientes[0]._id);
        // setCondPago(allCliente.forma_pago);
        // if(allCliente.forma_pago == "Credito"){
        //   setPago(false)
        // }else setPago(true)
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_SUCURSALES}/clientes/${cliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSucursales = res.data;
        setSucursales(allSucursales);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function jalaSucursal(value) {
    let split = value.split(",");
    let dir = split[0];
    let paq = split[1];

    setSelectedSucursal(value);
    setSucursal(dir);
  }

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }

    setTotalGeneral(
      (e * subTotal) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );

    setSaldo(
      (e * subTotal) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete) -
        efectivo -
        tdc -
        transfer
    );
  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }

    let temp =
      (selectedImpuesto * subTotal) / 100 +
      subTotal +
      parseFloat(e) +
      parseFloat(comisionFlete) -
      parseFloat((descuentoNumero * subTotal) / 100);

    setTotalGeneral(temp);

    setSaldo(temp - efectivo - tdc - transfer);
  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }

    let temp =
      (selectedImpuesto * subTotal) / 100 +
      subTotal +
      parseFloat(e) +
      parseFloat(comisionTDC) -
      parseFloat((descuentoNumero * subTotal) / 100);

    setTotalGeneral(temp);

    setSaldo(temp - efectivo - tdc - transfer);
  }

  // function autorizaCambio() {
  //   if (password == randomCambiaPrecio) {
  //     setCambiarPrecio(true);
  //     toggleCambiaPrecio();
  //     Swal.fire("Good job!", "Se autorizo con exito", "success");
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "La contraseña es incorrecta!",
  //     });
  //   }
  // }

  function autorizaCambio() {
    if (password == PASSWORD_CAMBIA_PRECIO) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function solicitarAut() {
    setMailAutEnviado(true);

    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: "Cambio de Precio",
          email: "danysalame18@gmail.com",
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
       <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>La tienda ${user.nombre} ${user.apellido} esta solicitando un cambio de precio, si lo autorizas proporciona la clave ${randomCambiaPrecio}</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaEfectivo(e) {
    setEfectivo(e);
    setSaldo(
      total_general - e - tdc - transfer
      // - cortesia
    );
  }

  function jalaTransfer(e) {
    setTransfer(e);
    if (e > 0) {
      setDestino("");
    } else {
      setDestino("NA");
    }
    setSaldo(total_general - efectivo - tdc - e);
  }

  function jalaTDC(e) {
    setTDC(e);
    if (e > 0) {
      setTerminal("");
    } else {
      setTerminal("NA");
    }
    setSaldo(total_general - efectivo - e - transfer);
  }

  function jalaArea(e) {
    setSelectedArea(e);
    areas.map((a) => {
      if (a._id == e) {
        setSelectedAreaName(a.name);
      }
    });
  }

  function importarArchivo(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let hojas = [];

    if (name === "file") {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });

        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );
          hojas.push({
            data: XL_row_object,
            sheetName,
          });
        });

        let A1 = hojas[0].data;

        // Crear un objeto que mapee los SKU de los artículos a sus objetos correspondientes
        const articulosMap = {};
        articulosCatalogo.forEach((articulo) => {
          articulosMap[articulo.SKU] = articulo;
        });

        const articulosNoEncontrados = [];

        // Iterar sobre A1 una sola vez
        for (const a of A1) {
          const articulo = articulosMap[a.SKU];
          if (articulo) {
            inputFields.push({
              id: uuidv4(),
              articulos: articulo._id,
              piezasPorCaja: 0,
              cajas: 0,
              cantidad: a.Unidades,
              precio: a.Precio,
              precioOriginal: articulo.venta,
              total: a.Precio * a.Unidades,
              articulosNombre: articulo.SKU + " " +  articulo.nombre,
              observacionesArticulo: "NA",
              idInv: "",
              costoUnitario: 0,
              cantInv: 0,
            });
          } else {
            // Si no se encuentra el artículo, agregarlo a la lista de no encontrados
              articulosNoEncontrados.push(a.SKU);
          }
        }

        // Verificar si se encontraron artículos que no estaban en el mapa
        if (articulosNoEncontrados.length > 0) {
          const mensaje = `Los siguientes SKU no se encontraron: ${articulosNoEncontrados.join(
            ", "
          )}`;
          Swal.fire({
            title: "Artículos no encontrados",
            text: mensaje,
            icon: "warning",
            button: "Entendido",
          });
        }

        ActualizaTotales();
      };
    }
  }

  function importarArchivoAmazon(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let hojas = [];

    if (name === "file") {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });

        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );
          hojas.push({
            data: XL_row_object,
            sheetName,
          });
        });

        let A1 = hojas[0].data;

        // Crear un objeto que mapee los ASIN de los artículos a sus objetos correspondientes
        const articulosMap = {};
        articulosCatalogo.forEach((articulo) => {
          articulosMap[articulo.ASIN] = articulo;
        });

        const articulosNoEncontrados = [];

        // Iterar sobre A1 una sola vez
        for (const a of A1) {
          const articulo = articulosMap[a.ASIN];
          if (articulo) {
            inputFields.push({
              id: uuidv4(),
              articulos: articulo._id,
              piezasPorCaja: 0,
              cajas: 0,
              cantidad: a.Unidades,
              precio: a.Precio,
              precioOriginal: articulo.venta,
              total: a.Precio * a.Unidades,
              articulosNombre: articulo.SKU + " " +  articulo.nombre,
              observacionesArticulo: "NA",
              idInv: "",
              costoUnitario: 0,
              cantInv: 0,
            });
          } else {
            // Si no se encuentra el artículo, agregarlo a la lista de no encontrados
              articulosNoEncontrados.push(a.SKU);
          }
        }

        // Verificar si se encontraron artículos que no estaban en el mapa
        if (articulosNoEncontrados.length > 0) {
          const mensaje = `Los siguientes SKU no se encontraron: ${articulosNoEncontrados.join(
            ", "
          )}`;
          Swal.fire({
            title: "Artículos no encontrados",
            text: mensaje,
            icon: "warning",
            button: "Entendido",
          });
        }

        ActualizaTotales();
      };
    }
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Venta Directa</h3>
          {/* <Form > */}
          <Row>
            <Col md={2}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                disabled
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Cliente</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedCliente}
                required
                onChange={(e) => {
                  jalaCliente(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Sucursal</Label>
              <Input
                type="select"
                value={selectedSucursal}
                onChange={(e) => {
                  jalaSucursal(e.target.value);
                }}
              >
                <option value="">Selecciona una Sucursal</option>
                {sucursales
                  .sort((a, b) => (a.alias > b.alias ? 1 : -1))
                  .map((a) => {
                    if (a.is_active == "Si") {
                      return (
                        <option value={[a._id]}>{a.alias}</option>
                      );
                    }
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                type="select"
                value={selectedColaborador}
                // onChange={(e) => {
                //   setSelectedColaborador(e.target.value)
                // }}
                disabled
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

            {/* <Col md={1}>
              <Label>Paqueteria</Label>
              <Input
                type="text"
                placeholder="Paqueteria"
                value={paqueteria}
                required
                onChange={(e) => {
                  setPaqueteria(e.target.value);
                }}
              />
            </Col> */}
          </Row>
          <Row>
            <Col md={2}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                onChange={(e) => {
                  jalaArea(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            {/* <Col md={1}>
              <Label>Comision TDC</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionTDC}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionTDC(value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Flete</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionFlete}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionFlete(value);
                }}
              />
            </Col> */}

            {/* <Col md={1}>
              <Label>Descuento %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuento}
                onChange={(e) => {
                  calculaDescuento(e.target.value);
                }}
              />
            </Col> */}

            <Col md={1}>
              <Label>Impuestos %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={selectedImpuesto}
                onChange={(e) => {
                  calculaImpuesto(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Sub Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={subTotal}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>IVA</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={iva}
                disabled
                className={`form-control`}
              />
            </Col>
            {/* <Col md={1}>
              <Label>Descuento $</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuentoNumero}
                disabled
              />
            </Col> */}
            <Col md={1}>
              <Label>Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={total_general}
                disabled
                className={`form-control`}
              />
            </Col>

            <Col md={1}>
              <Label>Piezas</Label>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Piezas"
                value={total_cantidad}
                required
                disabled
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Cambio de Precio</Label>
              <Button
                size="sm"
                className="btn"
                color="info"
                onClick={(e) => toggleCambiaPrecio()}
              >
                Solicitar
              </Button>
            </Col>
          </Row>
          <br />
          {/* <Row>
            <Col md={3}>
            <Label>Mercado Libre</Label>
              <Input
                required
                type="file"
                name="file"
                id="file"
                onChange={(event) => importarArchivo(event)}
              />
            </Col>
            <Col md={3}>
            <Label>Amazon</Label>
              <Input
                required
                type="file"
                name="file"
                id="file"
                onChange={(event) => importarArchivoAmazon(event)}
              />
            </Col>
          </Row> */}

          {/* Tabla Articulos */}

          {/* <Form className={classes.root}> */}
          <Table borderless>
            <thead>
              <tr>
                {/* <th style={{ maxWidth: "120px" }}>Codigo Qr</th> */}
                <th>Buscador</th>
                <th>Articulo</th>
                <th>Lote</th>
                <th style={{ maxWidth: "60px" }}>Inventario</th>
                {/* <th>Observaciones</th> */}
                {/* <th style={{ maxWidth: "60px" }}>Cajas</th>
                <th style={{ maxWidth: "60px" }}>Pzs x Caja</th> */}
                <th style={{ maxWidth: "60px" }}>Cantidad</th>
                <th style={{ maxWidth: "60px" }}>Precio Original</th>
                <th style={{ maxWidth: "60px" }}>Precio</th>
                <th style={{ maxWidth: "80px" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {inputFields.map((inputField) => (
                <>
                  <tr
                    style={
                      inputField.cantInv < inputField.cantidad
                        ? { backgroundColor: "#ed8c8c" }
                        : undefined
                    }
                  >
                    {/* <td style={{ maxWidth: "120px" }}>
                    <Input
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </td> */}
                    <td>
                      <Autocomplete
                        size="small"
                        value={inputField.articulosNombre}
                        onChange={(event, selectedArticulo) => {
                          BuscaArticulo(inputField.id, selectedArticulo);
                        }}
                        options={options.sort(
                          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                        )}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.junta}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecciona"
                            variant="outlined"
                          />
                        )}
                        renderOption={(option) => (
                          <React.Fragment>
                            {option.SKU} {option.nombre}
                          </React.Fragment>
                        )}
                      />
                    </td>
                    <td>
                      <Input
                        name="articulo"
                        type="string"
                        placeholder="Articulo"
                        value={inputField.articulosNombre}
                        disabled
                      />
                    </td>
                    <td>
                      <Input
                        name="lote"
                        type="select"
                        value={inputField.idInv}
                        required
                        onChange={(event) => {
                          BuscaLote(inputField.id, event.target.value);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {inventarios
                          .sort((a, b) => (a.lote > b.lote ? 1 : -1))
                          .map((a) => {
                            if (a.idArticulo == inputField.articulos) {
                              return <option value={a._id}>{a.lote}</option>;
                            }
                          })}
                      </Input>
                    </td>
                    <td style={{ maxWidth: "60px" }}>
                      {inputField.idInv != "" ? (
                        <Input
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantInv}
                          required
                          disabled
                        />
                      ) : (
                        <Input
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantInv}
                          disabled
                        />
                      )}
                    </td>
                    {/* <td>
                <Input
                    name="observaciones"
                    type="text"
                    placeholder="observaciones"
                    value={inputField.observacionesArticulo}
                    required
                    onChange={(event) =>
                      handleChangeInputObservaciones(inputField.id, event)
                    }
                  />
                </td> */}
                    {/* <td style={{ maxWidth: "60px" }}>
                    {inputField.idInv != "" ? (
                      <Input
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCajas(inputField.id, event)
                      }
                    ></Input>
                    ):(
                      <Input
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      disabled
                    ></Input>
                    )}
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      name="piezasPorCaja"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </td> */}
                    <td style={{ maxWidth: "60px" }}>
                      {inputField.idInv != "" ? (
                        <Input
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(inputField.id, event)
                          }
                        />
                      ) : (
                        <Input
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          disabled
                        />
                      )}
                    </td>
                    <td style={{ maxWidth: "60px" }}>
                      <Input
                        // bsSize="sm"
                        name="precioOriginal"
                        type="number"
                        min="0"
                        step="any"
                        placeholder="PrecioOriginal"
                        value={inputField.precioOriginal}
                        disabled
                      />
                    </td>
                    <td style={{ maxWidth: "60px" }}>
                      <Input
                        style={
                          inputField.precioOriginal - 50 > inputField.precio &&
                          !cambiarPrecio
                            ? { backgroundColor: "#ed8c8c" }
                            : null
                        }
                        // bsSize="sm"
                        name="precio"
                        type="number"
                        min="0"
                        step="any"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </td>
                    <td style={{ maxWidth: "80px" }}>
                      <Input
                        name="total"
                        type="number"
                        min="0"
                        step="any"
                        placeholder="total"
                        value={inputField.total}
                        disabled
                      />
                    </td>
                    <td>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={handleAddFields}
                        tabindex="-1"
                      >
                        <i class="fas fa-plus"></i>
                      </Button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
            {/* <tr>
              <td></td>
              <td></td>
              <td style={{ maxWidth: "60px" }}></td>
                  <td style={{ maxWidth: "60px" }}>
                    <Label>Total Cajas</Label>
                  <Input
                type="number"
                min="0"
                step="any"
                placeholder="Cajas"
                value={total_cajas}
                disabled
              />
                  </td>
                </tr> */}
          </Table>
          <br />
          {/* <Row>
            <Col md={5}></Col>
            <Col md={1}>
            <Label>Total Cajas</Label>
                  <Input
                type="number"
                min="0"
                step="any"
                placeholder="Cajas"
                value={total_cajas}
                disabled
              /></Col>
          </Row> */}
          <Row>
            {/* </Row> */}
            <br />
            {/* <Col md={3}>
              <Input
                type="checkbox"
                onChange={(event) => setPago(event.currentTarget.checked)}
                checked={pago}
              />

              <h4 className="subMenuTitulo">Deseas Pagar la Nota?</h4>
              </Col> */}
          </Row>
          {/* </Form> */}
          {/* Termina Tabla Articulos */}
          <br />
          <Row>
            <Col md={3}>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={savePedido}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              {/* </Col>
            <Col md={1}> */}
              <Button
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          {/* </Form> */}
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Cortesia</h4>
            </ModalHeader>
            <ModalBody>
              <Input
                bsSize="sm"
                type="password"
                placeholder="Password"
                value={password}
                required
                onChange={(event) => setPassword(event.target.value)}
              />
              <br />
              <Row>
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => autorizaCambio()}
                  >
                    Autorizar
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Precio</h4>
            </ModalHeader>
            <ModalBody>
              {mailAutEnviado == false ? (
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => solicitarAut()}
                  >
                    Solicitar Autorizacion
                  </Button>
                </Col>
              ) : (
                <>
                  <Input
                    bsSize="sm"
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => autorizaCambio()}
                      >
                        Autorizar
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => solicitarAut()}
                      >
                        Reenviar Solicitud
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal> */}
        </div>
      ) : undefined}
    </>
  );
}

export default VentaDirectaCreate;
