import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";

function DashboardCompras() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);


  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");


  const [info, setInfo] = useState([]);
  const [graficasUnidadesNegocio, setGraficasUnidadesNegocio] = useState([]);
  const [graficasLineas, setGraficasLineas] = useState([]);
  const [graficasProveedores, setGraficasProveedores] = useState([]);
  const [graficasFamilias, setGraficasFamilias] = useState([]);
  const [graficasMeses, setGraficasMeses] = useState([]);
  const [valorComprado, setValorComprado] = useState(0);
  const [cantidadComprado, setcantidadComprado] = useState(0);

  const [unidadesNegocio, setUnidadesNegocio] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [meses, setMeses] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedUnidadNegocio, setSelectedUnidadNegocio] = useState("");
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [selectedLinea, setSelectedLinea] = useState("");
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedMes, setSelectedMes] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }

useEffect(()=>{
  axios
  .get(`${URL_DASHBOARDS}Compras/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;

      setInfo(allDisponible)

      let unicosCol = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idUnidadNegocio === value.idUnidadNegocio)
      );
      setUnidadesNegocio(unicosCol);

      let unicosFam = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idFamilia === value.idFamilia)
      );
      setFamilias(unicosFam);

      let unicosLin = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idLinea === value.idLinea)
      );
      setLineas(unicosLin);

      let unicosProv = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idProveedor === value.idProveedor)
      );
      setProveedores(unicosProv);

      let unicosMes = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.Mes === value.Mes)
      );
      setMeses(unicosMes);
      
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });

},[])


function BuscaInfo(){
  setInfo([])
  setSelectedUnidadNegocio("")
  setSelectedFamilia("")
  setSelectedLinea("")
  setSelectedLinea("")
  setSelectedProveedor("")
  setSelectedMes("")
  setCargando(true)
  axios
  .get(`${URL_DASHBOARDS}Compras/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;

      setInfo(allDisponible)

      let unicosCol = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idUnidadNegocio === value.idUnidadNegocio)
      );
      setUnidadesNegocio(unicosCol);

      let unicosFam = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idFamilia === value.idFamilia)
      );
      setFamilias(unicosFam);

      let unicosLin = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idLinea === value.idLinea)
      );
      setLineas(unicosLin);

      let unicosProv = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idProveedor === value.idProveedor)
      );
      setProveedores(unicosProv);

      let unicosMes = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.Mes === value.Mes)
      );
      setMeses(unicosMes);
      
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });
}

  useMemo(() => {
      let temp = info
          if (selectedMes) {
            temp = temp.filter((e) =>
              e.Mes.includes(selectedMes)
            );
          }
          if (selectedUnidadNegocio) {
            temp = temp.filter((e) =>
              e.idUnidadNegocio.includes(selectedUnidadNegocio)
            );
          }
          if (selectedFamilia) {
            temp = temp.filter((e) =>
              e.idFamilia.includes(selectedFamilia)
            );
          }
          if (selectedLinea) {
            temp = temp.filter((e) =>
              e.idLinea.includes(selectedLinea)
            );
          }

          if (selectedProveedor) {
            temp = temp.filter((e) =>
              e.idProveedor.includes(selectedProveedor)
            );
          }

        let agrupadoUnidadNegocio = temp.reduce(function (groups, item) {
          const val = item["idUnidadNegocio"];
          groups[val] = groups[val] || {
            idUnidadNegocio: item.idUnidadNegocio,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].unidadNegocio = item.unidadNegocio;
          groups[val].idUnidadNegocio = item.idUnidadNegocio;
          return groups;
        }, []);

        let otraUnidadNegocio = Object.values(agrupadoUnidadNegocio).sort((a, b) =>
          a.cantidad < b.cantidad ? 1 : -1
        );

        setGraficasUnidadesNegocio(otraUnidadNegocio);

        let agrupadoLinea = temp.reduce(function (groups, item) {
          const val = item["idLinea"];
          groups[val] = groups[val] || {
            idLinea: item.idLinea,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].linea = item.linea;
          groups[val].idLinea = item.idLinea;
          return groups;
        }, []);

        let otraLinea = Object.values(agrupadoLinea).sort((a, b) =>
          a.cantidad < b.cantidad ? 1 : -1
        );

        setGraficasLineas(otraLinea);

        let agrupadoFamilia = temp.reduce(function (groups, item) {
          const val = item["idFamilia"];
          groups[val] = groups[val] || {
            idFamilia: item.idFamilia,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].familia = item.familia;
          groups[val].idFamilia = item.idFamilia;
          return groups;
        }, []);

        let otraFamilia = Object.values(agrupadoFamilia).sort((a, b) =>
          a.cantidad < b.cantidad ? 1 : -1
        );

        setGraficasFamilias(otraFamilia);


        let agrupadoMes = temp.reduce(function (groups, item) {
          const val = item["Mes"];
          groups[val] = groups[val] || {
            Mes: item.Mes,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].Mes = item.Mes;
          return groups;
        }, []);

        let otraMes = Object.values(agrupadoMes).sort((a, b) =>
          a.cantidad < b.cantidad ? 1 : -1
        );

        setGraficasMeses(otraMes);


        let agrupadoProveedor = temp.reduce(function (groups, item) {
          const val = item["idProveedor"];
          groups[val] = groups[val] || {
            idProveedor: item.idProveedor,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].proveedor = item.proveedor;
          groups[val].idProveedor = item.idProveedor;
          return groups;
        }, []);

        let otraProveedor = Object.values(agrupadoProveedor).sort((a, b) =>
          a.cantidad < b.cantidad ? 1 : -1
        );

        setGraficasProveedores(otraProveedor);

        let TVal = temp.map((c) => parseFloat(c.total));
        let TV = TVal.reduce((t, total, index) => t + total, 0);
        setValorComprado(TV);

        let TCan = temp.map((c) => parseFloat(c.cantidad));
        let TC = TCan.reduce((t, total, index) => t + total, 0);
        setcantidadComprado(TC);

  }, [info, selectedMes, selectedUnidadNegocio, selectedFamilia, selectedLinea, selectedProveedor]);


  useLayoutEffect(() => {
    
    // Grafica UnidadesNegocio
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
        layout: root.verticalLayout,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{unidadNegocio} {value}",
        }),
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "cantidad",
        categoryField: "unidadNegocio",
        endAngle: 270,
      })
    );

    series.states.create("hidden", {
      endAngle: -90,
    });

    chart.children.unshift(
      am5.Label.new(root, {
        text: "UnidadesNegocio",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    series.labels.template.set("forceHidden", true);
    series.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    series.data.setAll(graficasUnidadesNegocio);
    series.appear(1000, 100);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: "unidadNegocio",
      })
    );

    legend.data.setAll(series.dataItems);

    // Grafica Lineas
    let rootLineas = am5.Root.new("chartdivLineas");

    rootLineas.setThemes([am5themes_Animated.new(rootLineas)]);

    let chartLineas = rootLineas.container.children.push(
      am5percent.PieChart.new(rootLineas, {
        endAngle: 270,
        layout: rootLineas.verticalLayout,
        tooltip: am5.Tooltip.new(rootLineas, {
          labelText: "{linea} {value}",
        }),
      })
    );

    let seriesLineas = chartLineas.series.push(
      am5percent.PieSeries.new(rootLineas, {
        valueField: "cantidad",
        categoryField: "linea",
        endAngle: 270,
      })
    );

    seriesLineas.states.create("hidden", {
      endAngle: -90,
    });

    chartLineas.children.unshift(
      am5.Label.new(rootLineas, {
        text: "Lineas",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesLineas.labels.template.set("forceHidden", true);
    seriesLineas.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesLineas.data.setAll(graficasLineas);
    seriesLineas.appear(1000, 100);

    let legendLineas = chartLineas.children.push(
      am5.Legend.new(rootLineas, {
        nameField: "linea",
      })
    );

    legendLineas.data.setAll(seriesLineas.dataItems);

    // Grafica Familias
    let rootFamilias = am5.Root.new("chartdivFamilias");

    rootFamilias.setThemes([am5themes_Animated.new(rootFamilias)]);

    let chartFamilias = rootFamilias.container.children.push(
      am5percent.PieChart.new(rootFamilias, {
        endAngle: 270,
        layout: rootFamilias.verticalLayout,
        tooltip: am5.Tooltip.new(rootFamilias, {
          labelText: "{familia} {value}",
        }),
      })
    );

    let seriesFamilias = chartFamilias.series.push(
      am5percent.PieSeries.new(rootFamilias, {
        valueField: "cantidad",
        categoryField: "familia",
        endAngle: 270,
      })
    );

    seriesFamilias.states.create("hidden", {
      endAngle: -90,
    });

    chartFamilias.children.unshift(
      am5.Label.new(rootFamilias, {
        text: "Familias",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesFamilias.labels.template.set("forceHidden", true);
    seriesFamilias.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesFamilias.data.setAll(graficasFamilias);
    seriesFamilias.appear(1000, 100);

    let legendFamilias = chartFamilias.children.push(
      am5.Legend.new(rootFamilias, {
        nameField: "familia",
      })
    );

    legendFamilias.data.setAll(seriesFamilias.dataItems);


    // Grafica Mes
     let rootMeses = am5.Root.new("chartdivMeses");

     rootMeses.setThemes([am5themes_Animated.new(rootMeses)]);
 
     let chartMeses = rootMeses.container.children.push(
       am5percent.PieChart.new(rootMeses, {
         endAngle: 270,
         layout: rootMeses.verticalLayout,
         tooltip: am5.Tooltip.new(rootMeses, {
           labelText: "{Mes} {value}",
         }),
       })
     );
 
     let seriesMeses = chartMeses.series.push(
       am5percent.PieSeries.new(rootMeses, {
         valueField: "cantidad",
         categoryField: "Mes",
         endAngle: 270,
       })
     );
 
     seriesMeses.states.create("hidden", {
       endAngle: -90,
     });
 
     chartMeses.children.unshift(
       am5.Label.new(rootMeses, {
         text: "Mes",
         fontSize: 25,
         fontWeight: "500",
         textAlign: "center",
         x: am5.percent(50),
         centerX: am5.percent(50),
         paddingTop: 0,
         paddingBottom: 0,
       })
     );
 
     seriesMeses.labels.template.set("forceHidden", true);
     seriesMeses.ticks.template.setAll({
       visible: false,
       stroke: am5.color("rgb(252, 252, 252)"),
     });
     seriesMeses.data.setAll(graficasMeses);
     seriesMeses.appear(1000, 100);
 
     let legendMeses = chartMeses.children.push(
       am5.Legend.new(rootMeses, {
         nameField: "Mes",
       })
     );
 
     legendMeses.data.setAll(seriesMeses.dataItems);



     // Grafica Proveedores
    let rootProveedores = am5.Root.new("chartdivProveedores");

    rootProveedores.setThemes([am5themes_Animated.new(rootProveedores)]);

    let chartProveedores = rootProveedores.container.children.push(
      am5percent.PieChart.new(rootProveedores, {
        endAngle: 270,
        layout: rootProveedores.verticalLayout,
        tooltip: am5.Tooltip.new(rootProveedores, {
          labelText: "{proveedor} {value}",
        }),
      })
    );

    let seriesProveedores = chartProveedores.series.push(
      am5percent.PieSeries.new(rootProveedores, {
        valueField: "cantidad",
        categoryField: "proveedor",
        endAngle: 270,
      })
    );

    seriesProveedores.states.create("hidden", {
      endAngle: -90,
    });

    chartProveedores.children.unshift(
      am5.Label.new(rootProveedores, {
        text: "Proveedores",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesProveedores.labels.template.set("forceHidden", true);
    seriesProveedores.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesProveedores.data.setAll(graficasProveedores);
    seriesProveedores.appear(1000, 100);

    let legendProveedores = chartProveedores.children.push(
      am5.Legend.new(rootProveedores, {
        nameField: "proveedor",
      })
    );

    legendProveedores.data.setAll(seriesProveedores.dataItems);




    return () => {
      root.dispose();
      rootLineas.dispose();
      rootProveedores.dispose();
      rootFamilias.dispose();
      rootMeses.dispose();
    };
  }, [graficasUnidadesNegocio, graficasLineas, graficasFamilias,  graficasMeses, graficasProveedores]);

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.menu_cantidad ? ( */}
      <div className="card container col-12">
        <Row>
          <Col md={8}>
            <Button
              size="sm"
              href="/Dashboards"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Col>
        </Row>
        <h3 align="center">Dashboard Compras</h3>
        <Row>
        <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <h4></h4>
            <Button
              size="sm"
              className="btn"
              id="botonListado"
              onClick={BuscaInfo}
              color="info"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
        <br />
          <Row>

          <Col md={2}>
              <Label className="mr-sm-2">Meses</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedMes}
                required
                onChange={(e) => {
                  setSelectedMes(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {meses
                  // .sort((a, b) => (a.Mes > b.Mes ? 1 : -1))
                  .map((a) => {
                    return <option value={a.Mes}>{a.Mes}</option>;
                  })}
              </Input>
            </Col>

                <Col md={2}>
              <Label className="mr-sm-2">UnidadesNegocio</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedUnidadNegocio}
                required
                onChange={(e) => {
                  setSelectedUnidadNegocio(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {unidadesNegocio
                  .sort((a, b) => (a.unidadNegocio > b.unidadNegocio ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idUnidadNegocio}>{a.unidadNegocio}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Familias</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedFamilia}
                required
                onChange={(e) => {
                  setSelectedFamilia(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {familias
                  .sort((a, b) => (a.familia > b.Familia ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idFamilia}>{a.familia}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Lineas</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedLinea}
                required
                onChange={(e) => {
                  setSelectedLinea(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {lineas
                  .sort((a, b) => (a.linea > b.linea ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idLinea}>{a.linea}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Proveedores</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                required
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.proveedor > b.proveedor ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idProveedor}>{a.proveedor}</option>;
                  })}
              </Input>
            </Col>

        </Row> 

        <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
        a
      >
        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              PIEZAS
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {}).format(cantidadComprado)}
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              COMPRAS $
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(valorComprado)}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </div>

      
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div id="chartdiv" style={{ width: "40%", height: "500px" }}></div>
          <div
            id="chartdivLineas"
            style={{ width: "40%", height: "500px" }}
          ></div>
        </div>

        <br />
        <br />
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            id="chartdivFamilias"
            style={{ width: "40%", height: "500px" }}
          ></div>

          <div
            id="chartdivMeses"
            style={{ width: "40%", height: "500px" }}
          ></div>
        </div>

        <br />
        <br />
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >


        </div>

      </div>


      {/* ) : undefined}  */}
    </>
  );
}
export default DashboardCompras;
