import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button, Label } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCompras() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_compras ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
          {user.menu_oc ?(
            <Button href="/ListadoOrdenesCompra" className="botonesMenu" color="success">
              <i class="fas fa-shopping-basket fa-7x"></i>
              <br />
              <br />
              Ordenes de Compra
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-shopping-basket fa-7x"></i>
          <br />
          <br />
          Ordenes de Compra
        </Button> }
          </Col>
        <Col md={4} align="center">
          {user.pendiente_embarcar  ?(
            <Button href="/ListadoPendEmbarcar" className="botonesMenu" color="success">
              <i class="fas fa-plane-departure fa-7x"></i>
              <br />
              <br />
              Pendiente Embarcar
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-plane-departure fa-7x"></i>
          <br />
          <br />
          Pendiente Embarcar
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.embarques  ?(
            <Button href="/ListadoEmbarques" className="botonesMenu" color="success">
              <i class="fas fa-ship fa-7x"></i>
              <br />
              <br />
              Embarques
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-ship fa-7x"></i>
          <br />
          <br />
          Embarques
        </Button> }
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={4} align="center">
          {user.pendiente_recibir ?(
            <Button href="/ListadoPendRecibir" className="botonesMenu" color="success">
              <i class="fas fa-plane-arrival fa-7x"></i>
              <br />
              <br />
              Pendiente Recibir
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-plane-arrival fa-7x"></i>
          <br />
          <br />
          Pendiente Recibir
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_compras ?(
            <Button href="/ListadoCompras" className="botonesMenu" color="success">
              <i class="fas fa-cart-plus fa-7x"></i>
              <br />
              <br />
              Entradas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-cart-plus fa-7x"></i>
          <br />
          <br />
          Entradas
        </Button> }
          </Col>
        <Col md={4} align="center">
            {user.menu_proveedores ?(
            <Button href="/Proveedores" className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Proveedores
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Proveedores
            </Button> }
          </Col>
        </Row>
        <br/>
        <Row>
        <Col md={4} align="center">
            {user.menu_compras ?(
            <Button href="/ConceptosGastosEmbarques" className="botonesMenu" color="success">
            <i class="fas fa-bars fa-7x"></i>
              <br />
              <br />
              Conceptos Gastos <br /> Embarque
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-bars fa-7x"></i>
              <br />
              <br />
              Conceptos Gastos <br /> Embarque
            </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_compras ?(
            <Button href="/ListadoReabasto" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Reabasto
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Reabasto
        </Button> }
          </Col>
         
          {/* <Col md={4} align="center">
          {user.menu_oc ?(
            <Button href="/ListadoOrdenesComprasViejas" className="botonesMenu" color="success">
              <i class="fas fa-shopping-basket fa-7x"></i>
              <br />
              <br />
              OC Viejas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-shopping-basket fa-7x"></i>
          <br />
          <br />
          OC Viejas
        </Button> }
          </Col> 
        </Row>
        <hr />
        <h3>Control de Calidad</h3>
        <Row>
        <Col md={4} align="center">
          {user.menuCalidad ?(
            <Button href="/RegistroControlCalidad" className="botonesMenu" color="success">
              <i class="far fa-check-square fa-7x"></i>
              <br />
              <br />
              Registro
              <br />
              Control Calidad
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="far fa-check-square fa-7x"></i>
          <br />
          <br />  
          Registro
          <br />
          Control Calidad
        </Button> }
          </Col> */}
          {/* <Col md={4} align="center">
          {user.menuCalidad ?(
            <Button href="/ListadoControlCalidad" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Listado
              <br />
              Control Calidad
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />  
          Listado
          <br />
          Control Calidad
        </Button> }
          </Col> */}
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCompras;
