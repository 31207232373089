import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";

function ListadoArticulos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_UNIDADES_NEGOCIO = process.env.REACT_APP_URL_UNIDADES_NEGOCIO;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [articulos, setArticulos] = useState("");
  const [activo, setActivo] = useState("");

  const [codigo, setCodigo] = useState("");
  const [codigoSAT, setCodigoSAT] = useState("");
  const [nombre, setNombre] = useState("");
  const [modelo, setModelo] = useState("");
  const [piezasPorCaja, setPiezasPorCaja] = useState("");
  const [costoUSD, setCostoUSD] = useState("");
  const [costoCNY, setCostoCNY] = useState("");
  const [costoMX, setCostoMX] = useState("");
  const [venta, setVenta] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [EAN, setEAN] = useState("NA");
  const [SKU, setSKU] = useState("NA");
  const [ASIN, setASIN] = useState("NA");
  const [linkML, setLinkML] = useState("");
  const [linkShein, setLinkShein] = useState("");
  const [linkAmazon, setLinkAmazon] = useState("");
  const [linkCoppel, setLinkCoppel] = useState("");
  const [linkLiverpool, setLinkLiverpool] = useState("");
  const [linkWalmart, setLinkWalmart] = useState("");
  const [linkElektra, setLinkElektra] = useState("");

  const [imagenes, setImagenes] = useState("");
  const [especiales, setEspeciales] = useState("");
  const [cargadoML, setCargadoML] = useState("");
  const [cargadoShein, setCargadoShein] = useState("");
  const [cargadoAmazon, setCargadoAmazon] = useState("");
  const [cargadoCoppel, setCargadoCoppel] = useState("");
  const [cargadoWalmart, setCargadoWalmart] = useState("");
  const [cargadoLiverpool, setCargadoLiverpool] = useState("");
  const [cargadoElektra, setCargadoElektra] = useState("");

  const [largoProducto, setLargoProducto] = useState(0);
  const [anchoProducto, setAnchoProducto] = useState(0);
  const [altoProducto, setAltoProducto] = useState(0);
  const [pesoProducto, setPesoProducto] = useState(0);
  const [largoEmpaque, setLargoEmpaque] = useState(0);
  const [anchoEmpaque, setAnchoEmpaque] = useState(0);
  const [altoEmpaque, setAltoEmpaque] = useState(0);
  const [pesoEmpaque, setPesoEmpaque] = useState(0);
  const [stockMinimo, setStockMinimo] = useState(0);
  const [stockMaximo, setStockMaximo] = useState(0);
  const [indiceRotacion, setIndiceRotacion] = useState(0);

  const [lineas, setLineas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [unidadesNegocio, setUnidadesNegocio] = useState([]);
  const [proveedores, setProveedores] = useState([]);


  const [selectedCodigo, setSelectedCodigo] = useState(0);
  const [selectedNombre, setSelectedNombre] = useState(0);
  const [selectedLinea, setSelectedLinea] = useState(0);
  const [selectedMarca, setSelectedMarca] = useState(0);
  const [selectedFamilia, setSelectedFamilia] = useState(0);
  const [selectedUnidadNegocio, setSelectedUnidadNegocio] = useState(0);
  const [selectedProveedor, setSelectedProveedor] = useState(0);

  const [selectedLineaEdit, setSelectedLineaEdit] = useState("");
  const [selectedMarcaEdit, setSelectedMarcaEdit] = useState("");
  const [selectedFamiliaEdit, setSelectedFamiliaEdit] = useState("");
  const [selectedUnidadNegocioEdit, setSelectedUnidadNegocioEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");
  const [selectedActivo, setSelectedActivo] = useState("");
  const [idEditArticulo, setIdEditArticulo] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 3650);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(12), (val, index) => index + year);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);


  const [totalCaptura, setTotalCaptura] = useState(1);
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      fob: 0,
      venta: 0
    },
  ]);
  const [modalPrecios, setModalPrecios] = useState(false);
  const togglePrecios = () => setModalPrecios(!modalPrecios);
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        // Array para pagination
        let arrayTabla = allArticulos
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              activo: a.is_active,
              codigo: a.codigo,
              codigoSAT: a.codigoSAT,
              nombre: a.nombre,
              modelo: a.modelo,
              piezasPorCaja: a.piezasPorCaja,
              costoUSD: a.costoUSD,
              costoCNY: a.costoCNY,
              costoMX: a.costoMX,
              venta: a.venta,
              observaciones: a.observaciones,
              EAN: a.EAN,
              SKU: a.SKU,
              ASIN: a.ASIN,
              imagenes: a.imagenes,
              especiales: a.especiales,
              linkML: a.linkML,
              linkShein: a.linkShein,
              linkAmazon: a.linkAmazon,
              linkCoppel: a.linkCoppel,
              linkWalmart: a.linkWalmart,
              linkLiverpool: a.linkLiverpool,
              linkElektra: a.linkElektra,
              cargadoML: a.cargadoML,
              cargadoShein: a.cargadoShein,
              cargadoAmazon: a.cargadoAmazon,
              cargadoCoppel: a.cargadoCoppel,
              cargadoWalmart: a.cargadoWalmart,
              cargadoLiverpool: a.cargadoLiverpool,
              cargadoElektra: a.cargadoElektra,
              largoProducto: a.largoProducto,
              anchoProducto: a.anchoProducto,
              altoProducto: a.altoProducto,
              pesoProducto: a.pesoProducto,
              largoEmpaque: a.largoEmpaque,
              anchoEmpaque: a.anchoEmpaque,
              altoEmpaque: a.altoEmpaque,
              pesoEmpaque: a.pesoEmpaque,
              stockMinimo: a.stockMinimo,
              stockMaximo: a.stockMaximo,
              indiceRotacion: a.indiceRotacion,
              linea: a.lineas[0].name,
              marca: a.marcas[0].name,
              familia: a.familias[0].name,
              unidadNegocio: a.unidadesNegocio[0].name,
              proveedor: a.proveedores[0].nombre_comercial,
              idLinea: a.lineas[0]._id,
              idMarca: a.marcas[0]._id,
              idFamilia: a.familias[0]._id,
              idUnidadNegocio: a.unidadesNegocio[0]._id,
              idProveedor: a.proveedores[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setArticulos(allArticulos);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_MARCAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allMarcas = res.data;
        setMarcas(allMarcas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_UNIDADES_NEGOCIO, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allUnidadesNegocio = res.data;
        setUnidadesNegocio(allUnidadesNegocio);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
    .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const ListaArticulos = comments.sort((a, b) =>
    a.nombre > b.nombre ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaArticulos.map((a) => {
      if (
        (selectedLinea == 0 || selectedLinea == a.idLinea) &&
        (selectedMarca == 0 || selectedMarca == a.idMarca) &&
        (selectedFamilia == 0 || selectedFamilia == a.idFamilia) &&
        (selectedUnidadNegocio == 0 || selectedUnidadNegocio == a.idUnidadNegocio) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedor) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.codigo,
          a.nombre,
          a.piezasPorCaja,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoUSD),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoMX),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.venta),
          a.linea,
          a.marca,
          // a.familia,
          a.unidadNegocio,
          a.proveedor,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Articulos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Modelo",
          "Pzas x Caja",
          "FOB USD",
          "Costo MX",
          "Venta",
          "Linea",
          "Marca",
          // "Familia",
          "UnidadNegocio",
          "Proveedor",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Articulos.pdf`);
  }

  function excel() {
    const dataExcel = ListaArticulos.map((a) => {
      if (
        (selectedLinea == 0 || selectedLinea == a.idLinea) &&
        (selectedMarca == 0 || selectedMarca == a.idMarca) &&
        (selectedFamilia == 0 || selectedFamilia == a.idFamilia) &&
        (selectedUnidadNegocio == 0 || selectedUnidadNegocio == a.idUnidadNegocio) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedor) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          Id: a._id,
          SKU: a.SKU,
          ASIN: a.ASIN,
          Modelo: a.nombre,
          PiezasPorCaja: a.piezasPorCaja,
          FOBUSD: a.costoUSD,
          CostoMX: a.costoMX,
          Venta: a.venta,
          Linea: a.linea,
          Marca: a.marca,
          // Familia: a.familia,
          UnidadNegocio: a.unidadNegocio,
          Proveedor: a.proveedor,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoArticulos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoArticulos",
        sheetFilter: [
          "Id",
          "SKU",
          "ASIN",
          "Modelo",
          "PiezasPorCaja",
          "FOBUSD",
          "CostoMX",
          "Venta",
          "Linea",
          "Marca",
          // "Familia",
          "UnidadNegocio",
          "Proveedor",
        ],
        sheetHeader: [
          "Id",
          "SKU",
          "ASIN",
          "Modelo",
          "PiezasPorCaja",
          "FOBUSD",
          "CostoMX",
          "Venta",
          "Linea",
          "Marca",
          // "Familia",
          "UnidadNegocio",
          "Proveedor",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaArticulos.map((a) => {
      if (
        (selectedLinea == 0 || selectedLinea == a.idLinea) &&
        (selectedMarca == 0 || selectedMarca == a.idMarca) &&
        (selectedFamilia == 0 || selectedFamilia == a.idFamilia) &&
        (selectedUnidadNegocio == 0 || selectedUnidadNegocio == a.idUnidadNegocio) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedor) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.codigo,
          a.nombre,
          a.piezasPorCaja,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoUSD),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoMX),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.venta),
          a.linea,
          a.marca,
          // a.familia,
          a.unidadNegocio,
          a.proveedor,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Articulos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Modelo",
          "Pzas x Caja",
          "FOB USD",
          "Costo MX",
          "Venta",
          "Linea",
          "Marca",
          // "Familia",
          "UnidadNegocio",
          "Proveedor",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Articulos",
          email: mailTo,
          fileName: "ListadoArticulos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Articulos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  
  function jalaFoto(
    idEdit
  ) {
    setPhoto("")
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));


    setIdEditArticulo(idEdit);
    toggleFoto();
  }


  function jalaInfo(
   codigo,
   codigoSAT,
   nombre,
   modelo,
   piezasPorCaja,
   costoUSD,
   costoCNY,
   costoMX,
   venta,
   observaciones,
   EAN,
   SKU,
   ASIN,
   imagenes,
   especiales,
   linkML,
   linkShein,
   linkAmazon,
   linkCoppel,
   linkWalmart,
   linkLiverpool,
   linkElektra,
   cargadoML,
   cargadoShein,
   cargadoAmazon,
   cargadoCoppel,
   cargadoWalmart,
   cargadoLiverpool,
   cargadoElektra,
   largoProducto,
   anchoProducto,
   altoProducto,
   pesoProducto,
   largoEmpaque,
   anchoEmpaque,
   altoEmpaque,
   pesoEmpaque,
   stockMinimo,
   stockMaximo,
   indiceRotacion,


    idLinea,
    idMarca,
    idFamilia,
    idUnidadNegocio,
    idProveedor,
    idEdit
  ) {
    setCodigo(codigo);
    setCodigoSAT(codigoSAT);
    setNombre(nombre);
    setModelo(modelo);
    setPiezasPorCaja(piezasPorCaja);
    setCostoUSD(costoUSD);
    setCostoCNY(costoCNY);
    setCostoMX(costoMX);
    setVenta(venta);
    setObservaciones(observaciones);
    setEAN(EAN);
    setSKU(SKU);
    setASIN(ASIN);
    setImagenes(imagenes);
    setEspeciales(especiales);
    setLinkML(linkML);
    setLinkShein(linkShein);
    setLinkAmazon(linkAmazon);
    setLinkCoppel(linkCoppel);
    setLinkWalmart(linkWalmart);
    setLinkLiverpool(linkLiverpool);
    setLinkElektra(linkElektra);
    setCargadoML(cargadoML);
    setCargadoShein(cargadoShein);
    setCargadoAmazon(cargadoAmazon);
    setCargadoCoppel(cargadoCoppel);
    setCargadoWalmart(cargadoWalmart);
    setCargadoLiverpool(cargadoLiverpool);
    setCargadoElektra(cargadoElektra);
    setLargoProducto(largoProducto);
    setAnchoProducto(anchoProducto);
    setAltoProducto(altoProducto);
    setPesoProducto(pesoProducto);
    setLargoEmpaque(largoEmpaque);
    setAnchoEmpaque(anchoEmpaque);
    setAltoEmpaque(altoEmpaque);
    setPesoEmpaque(pesoEmpaque);
    setStockMinimo(stockMinimo);
    setStockMaximo(stockMaximo);
    setIndiceRotacion(indiceRotacion);
    
    setSelectedLineaEdit(idLinea);
    setSelectedMarcaEdit(idMarca);
    setSelectedFamiliaEdit(idFamilia);
    setSelectedUnidadNegocioEdit(idUnidadNegocio);
    setSelectedProveedorEdit(idProveedor);
    setIdEditArticulo(idEdit);
    toggleEdit();
  }

  function editArticulos(event) {
    event.preventDefault();
    const URL_ARTICULOS_EDIT = `${process.env.REACT_APP_URL_ARTICULOS}/${idEditArticulo}`;
    if (file == null) {
    axios
      .all([
        axios.patch(
          URL_ARTICULOS_EDIT,
          {
            codigo,
            codigoSAT,
            nombre,
            modelo,
            piezasPorCaja,
            costoUSD,
            costoCNY,
            costoMX,
            venta,
            observaciones,
            EAN,
            SKU,
            ASIN,
            imagenes,
            especiales,
            linkML,
            linkShein,
            linkAmazon,
            linkCoppel,
            linkWalmart,
            linkLiverpool,
            linkElektra,
            cargadoML,
            cargadoShein,
            cargadoAmazon,
            cargadoCoppel,
            cargadoWalmart,
            cargadoLiverpool,
            cargadoElektra,
            largoProducto,
            anchoProducto,
            altoProducto,
            pesoProducto,
            largoEmpaque,
            anchoEmpaque,
            altoEmpaque,
            pesoEmpaque,
            stockMinimo,
            stockMaximo,
            indiceRotacion,
            lineas: selectedLineaEdit,
            marcas: selectedMarcaEdit,
            familias: selectedFamiliaEdit,
            unidadesNegocio: selectedUnidadNegocioEdit,
            proveedores: selectedProveedorEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Articulo",
            detalle: `${nombre} ${codigo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    } else{
      axios
      .all([
        axios.patch(
          URL_ARTICULOS_EDIT,
          {
            codigo,
            nombre,
            piezasPorCaja,
            costoUSD,
            costoCNY,
            costoMX,
            venta,
            lineas: selectedLineaEdit,
            marcas: selectedMarcaEdit,
            familias: selectedFamiliaEdit,
            unidadesNegocio: selectedUnidadNegocioEdit,
            proveedores: selectedProveedorEdit,
            EAN,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("articulo", idEditArticulo);

        axios
          .post(URL_FILEPOST, formData, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Articulo",
                detalle: `${nombre} ${codigo}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            );

            toggleEdit();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }
}
  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "SKU", field: "SKU", sortable: true },
    { name: "ASIN", field: "ASIN", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Pzas Caja", field: "piezasPorCaja", sortable: true },
    { name: "FOB USD", field: "costoUSD", sortable: true },
    { name: "Costo MX", field: "costoMX", sortable: true },
    { name: "Venta", field: "venta", sortable: true },
    { name: "Linea", field: "linea", sortable: true },
    { name: "Marca", field: "marca", sortable: true },
    // { name: "Familia", field: "familia", sortable: true },
    { name: "Unidad Negocio", field: "unidadNegocio", sortable: true },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment._id.toLowerCase().includes(search.toLowerCase()) ||
          comment.SKU.toLowerCase().includes(search.toLowerCase()) ||
          comment.ASIN.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.linea.toLowerCase().includes(search.toLowerCase()) ||
          comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          // comment.familia.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidadNegocio.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.piezasPorCaja.toString().includes(search) ||
          comment.costoUSD.toString().includes(search) ||
          comment.costoMX.toString().includes(search) ||
          comment.venta.toString().includes(search)
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedCodigo)
      );
    }
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedNombre)
      );
    }
    if (selectedLinea) {
      computedComments = computedComments.filter((e) =>
        e.idLinea.includes(selectedLinea)
      );
    }
    if (selectedMarca) {
      computedComments = computedComments.filter((e) =>
        e.idMarca.includes(selectedMarca)
      );
    }
    // if (selectedFamilia) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idFamilia.includes(selectedFamilia)
    //   );
    // }
    if (selectedUnidadNegocio) {
      computedComments = computedComments.filter((e) =>
        e.idUnidadNegocio.includes(selectedUnidadNegocio)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }
    if (selectedActivo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(selectedActivo)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "piezasPorCaja" &&
      sorting.field != "costoUSD" &&
      sorting.field != "costoMX" &&
      sorting.field != "venta"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "piezasPorCaja" ||
        sorting.field == "costoUSD" ||
        sorting.field == "costoMX" ||
        sorting.field == "venta")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "piezasPorCaja" ||
        sorting.field == "costoUSD" ||
        sorting.field == "costoMX" ||
        sorting.field == "venta")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCodigo,
    selectedNombre,
    selectedLinea,
    selectedMarca,
    // selectedFamilia,
    selectedUnidadNegocio,
    selectedProveedor,
    selectedActivo,
  ]);

  function QRGen(idArt, SKU, nombre) {
    const doc = new jsPDF("landscape", "mm", [55, 40]);

    let codigoQR = idArt;
    var base64 = qrcode.toDataURL(codigoQR);
    var img3 = new Image();
    img3.src = base64;
    doc.addImage(img3, "png", 13, 14, 25, 25);
    doc.setFontSize(8);
    doc.text(`ID: ${idArt}`, 3, 4);
    doc.text(`SKU: ${SKU}`, 3, 7.5);

    // Función para dividir el texto en dos líneas si es necesario
    function splitTextIntoLines(text, maxLength) {
        if (text.length <= maxLength) {
            return [text]; // No es necesario dividir
        }
        const firstLine = text.slice(0, maxLength);
        const secondLine = text.slice(maxLength);
        return [firstLine, secondLine];
    }

    // Tamaño máximo de caracteres por línea
    const maxLineLength = 40; // Ajusta según tu necesidad

    // Divide el nombre en líneas si es necesario
    const nombreLines = splitTextIntoLines(nombre, maxLineLength);

    doc.text(nombreLines[0], 3, 11);
    if (nombreLines[1]) {
        doc.text(nombreLines[1], 3, 14.5); // Ajusta la posición vertical según tu necesidad
    }

    doc.save(`QR.pdf`);
}


    function importarArchivo(event){
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let hojas = [];
   
    if (name === "file") {
  
      let reader = new FileReader();
        reader.readAsArrayBuffer(target.files[0]);
        reader.onloadend = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
  
          workbook.SheetNames.forEach(function (sheetName) {
            // Here is your object
            var XL_row_object = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName]
            );
            hojas.push({
              data: XL_row_object,
              sheetName,
            });
          });
  
          let A1 = hojas[0].data
  
          let TC = totalCaptura
          A1.map((a)=>{
             TC = TC + 1
            setTotalCaptura(TC)
          inputFields.push({
            id: uuidv4(),
            articulos: a.Id,
            fob: a.FOBUSD,
            venta: a.Venta
          })
          })
  
        }
    }
  }
  // console.log("hola", inputFields)

function actualizaPrecios(event){
  event.preventDefault();
    toggleProgreso();
    let totalPedido = inputFields.length
  inputFields.map((a)=>{
    axios
    .patch(
      `${URL_ARTICULOS}/${a.articulos}`,
      {
        costoUSD: a.fob,
        venta: a.venta
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
        },
      }
    ).then(() => {
      totalPedido = totalPedido - 1;
      console.log("hola", totalPedido)
      if (totalPedido == 1) {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Costos",
            detalle: "Costos Masivos",
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", `success`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    })
    // .catch((error) => {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Something went wrong!",
    //     footer: `${error.response.data}`,
    //   });
    //   console.log(error);
    // });
  })
}


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.articulos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ArticulosCreate"
                >
                  Nuevo Modelo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Modelo
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuArticulos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
              <Button
                  size="sm"
                  className="btn"
                  color="warning"
                  onClick={togglePrecios}
                >
                  Act. Precios 
                  
                </Button>

                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Articulos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Articulos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                    type="select"
                    value={selectedActivo}
                    onChange={(e) => {
                      setSelectedActivo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                    </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCodigo}
                  onChange={(e) => {
                    setSelectedCodigo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.SKU > b.SKU ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.SKU}</option>;
                    })}
                </Input>
              </td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNombre}
                  onChange={(e) => {
                    setSelectedNombre(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedLinea}
                  onChange={(e) => {
                    setSelectedLinea(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedMarca}
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              {/* <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedFamilia}
                  onChange={(e) => {
                    setSelectedFamilia(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {familias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td> */}
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedUnidadNegocio}
                  onChange={(e) => {
                    setSelectedUnidadNegocio(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {unidadesNegocio
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedProveedor}
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.SKU}</td>
                      <td>{a.ASIN}</td>
                      <td>{a.nombre}</td>
                      <td>{a.piezasPorCaja}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.costoUSD)}{" "}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.costoMX)}{" "}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.venta)}{" "}
                      </td>
                      <td>{a.linea} </td>
                      <td>{a.marca} </td>
                      {/* <td>{a.familia} </td>*/}
                      <td>{a.unidadNegocio} </td> 
                      <td>{a.proveedor} </td>
                      <td>
                        {user.articulos_create ? (
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => jalaFoto(a._id)}
                            >
                              <i class="fas fa-camera"></i>
                            </Button>

                            <Button
                              size="sm"
                              className="btn"
                              color="success"
                              onClick={(e) =>
                                QRGen(
                                  a._id,
                                  a.SKU,
                                  a.nombre,
                                  a.piezasPorCaja,
                                  a.linea,
                                  a.marca,
                                  a.familia,
                                  a.unidadNegocio
                                )
                              }
                            >
                              <i class="fas fa-qrcode"></i>
                            </Button>

                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.codigo,
                                  a.codigoSAT,
                                  a.nombre,
                                  a.modelo,
                                  a.piezasPorCaja,
                                  a.costoUSD,
                                  a.costoCNY,
                                  a.costoMX,
                                  a.venta,
                                  a.observaciones,
                                  a.EAN,
                                  a.SKU,
                                  a.ASIN,
                                  a.imagenes,
                                  a.especiales,
                                  a.linkML,
                                  a.linkShein,
                                  a.linkAmazon,
                                  a.linkCoppel,
                                  a.linkWalmart,
                                  a.linkLiverpool,
                                  a.linkElektra,
                                  a.cargadoML,
                                  a.cargadoShein,
                                  a.cargadoAmazon,
                                  a.cargadoCoppel,
                                  a.cargadoWalmart,
                                  a.cargadoLiverpool,
                                  a.cargadoElektra,
                                  a.largoProducto,
                                  a.anchoProducto,
                                  a.altoProducto,
                                  a.pesoProducto,
                                  a.largoEmpaque,
                                  a.anchoEmpaque,
                                  a.altoEmpaque,
                                  a.pesoEmpaque,
                                  a.stockMinimo,
                                  a.stockMaximo,
                                  a.indiceRotacion,
                                  a.idLinea,
                                  a.idMarca,
                                  a.idFamilia,
                                  a.idUnidadNegocio,
                                  a.idProveedor,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_ARTICULOS}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Ficha Tecnica
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/arcoshopArticulos/${idEditArticulo}.jpg`}
                  alt=""
                />
              </h6>
            ) : <h4>No se ha subido una Ficha Tecnica....</h4>}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Modelo {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
              <Col md={3}>
                <Label>Codigo de Origen</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Codigo SAT</Label>
                <Input
                  type="text"
                  placeholder="CodigoSAT"
                  value={codigoSAT}
                  required
                  onChange={(e) => {
                    setCodigoSAT(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Variante</Label>
                <Input
                  type="text"
                  placeholder="modelo"
                  value={modelo}
                  required
                  onChange={(e) => {
                    setModelo(e.target.value);
                  }}
                />
              </Col>
</Row>
<Row>
              <Col md={3}>
                <Label>Costo CNY</Label>
                <Input
                  type="number"
                  placeholder="Costo CNY"
                  value={costoCNY}
                  required
                  onChange={(e) => {
                    setCostoCNY(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>FOB USD</Label>
                <Input
                  type="number"
                  placeholder="FOB USD"
                  value={costoUSD}
                  required
                  onChange={(e) => {
                    setCostoUSD(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Costo MX</Label>
                <Input
                  type="number"
                  placeholder="Costo MX"
                  value={costoMX}
                  required
                  onChange={(e) => {
                    setCostoMX(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Venta</Label>
                <Input
                  type="number"
                  placeholder="Venta"
                  value={venta}
                  required
                  onChange={(e) => {
                    setVenta(e.target.value);
                  }}
                />
              </Col>
</Row>
<Row>
              <Col md={3}>
                <Label>Piezas Por Caja</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Piezas Por Caja"
                  value={piezasPorCaja}
                  required
                  onChange={(e) => {
                    setPiezasPorCaja(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>ASIN</Label>
                <Input
                  type="text"
                  placeholder="ASIN"
                  value={ASIN}
                  required
                  onChange={(e) => {
                    setASIN(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>EAN</Label>
                <Input
                  type="text"
                  placeholder="EAN"
                  value={EAN}
                  required
                  onChange={(e) => {
                    setEAN(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>SKU</Label>
                <Input
                  type="text"
                  placeholder="SKU"
                  value={SKU}
                  required
                  onChange={(e) => {
                    setSKU(e.target.value);
                  }}
                />
              </Col>
</Row>
<Row>
              <Col md={3}>
                <Label>Link ML</Label>
                <Input
                  type="text"
                  placeholder="LinkML"
                  value={linkML}
                  required
                  onChange={(e) => {
                    setLinkML(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Link Amazon</Label>
                <Input
                  type="text"
                  placeholder="LinkAmazon"
                  value={linkAmazon}
                  required
                  onChange={(e) => {
                    setLinkAmazon(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Link Shein</Label>
                <Input
                  type="text"
                  placeholder="LinkShein"
                  value={linkShein}
                  required
                  onChange={(e) => {
                    setLinkShein(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Link Coppel</Label>
                <Input
                  type="text"
                  placeholder="LinkCoppel"
                  value={linkCoppel}
                  required
                  onChange={(e) => {
                    setLinkCoppel(e.target.value);
                  }}
                />
              </Col>
              </Row>

              <Row>
              <Col md={3}>
                <Label>Link Walmart</Label>
                <Input
                  type="text"
                  placeholder="LinkWalmart"
                  value={linkWalmart}
                  required
                  onChange={(e) => {
                    setLinkWalmart(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Link Liverpool</Label>
                <Input
                  type="text"
                  placeholder="LinkLiverpool"
                  value={linkLiverpool}
                  required
                  onChange={(e) => {
                    setLinkLiverpool(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Link Elektra</Label>
                <Input
                  type="text"
                  placeholder="LinkElektra"
                  value={linkElektra}
                  required
                  onChange={(e) => {
                    setLinkElektra(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label className="mr-sm-2">Imagenes</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={imagenes}
                  required
                  onChange={(e) => {
                    setImagenes(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            </Row>

            <Row>
              
            <Col md={3}>
                <Label className="mr-sm-2">Cargado ML</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoML}
                  required
                  onChange={(e) => {
                    setCargadoML(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Amazon</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoAmazon}
                  required
                  onChange={(e) => {
                    setCargadoAmazon(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Shein</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoShein}
                  required
                  onChange={(e) => {
                    setCargadoShein(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Coppel</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoCoppel}
                  required
                  onChange={(e) => {
                    setCargadoCoppel(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
              </Row>
              <Row>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Walmart</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoWalmart}
                  required
                  onChange={(e) => {
                    setCargadoWalmart(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Liverpool</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoLiverpool}
                  required
                  onChange={(e) => {
                    setCargadoLiverpool(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Elektra</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoElektra}
                  required
                  onChange={(e) => {
                    setCargadoElektra(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            </Row>

            <Row>

            <Col md={3}>
                <Label>Largo Producto</Label>
                <Input
                  type="number"
                  placeholder="LargoProducto"
                  value={largoProducto}
                  required
                  onChange={(e) => {
                    setLargoProducto(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Ancho Producto</Label>
                <Input
                  type="number"
                  placeholder="AnchoProducto"
                  value={anchoProducto}
                  required
                  onChange={(e) => {
                    setAnchoProducto(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Alto Producto</Label>
                <Input
                  type="number"
                  placeholder="AltoProducto"
                  value={altoProducto}
                  required
                  onChange={(e) => {
                    setAltoProducto(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Peso Producto</Label>
                <Input
                  type="number"
                  placeholder="PesoProducto"
                  value={pesoProducto}
                  required
                  onChange={(e) => {
                    setPesoProducto(e.target.value);
                  }}
                />
              </Col>

            </Row>

            <Row>

            <Col md={3}>
                <Label>Largo Empaque</Label>
                <Input
                  type="number"
                  placeholder="LargoEmpaque"
                  value={largoEmpaque}
                  required
                  onChange={(e) => {
                    setLargoEmpaque(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Ancho Empaque</Label>
                <Input
                  type="number"
                  placeholder="AnchoEmpaque"
                  value={anchoEmpaque}
                  required
                  onChange={(e) => {
                    setAnchoEmpaque(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Alto Empaque</Label>
                <Input
                  type="number"
                  placeholder="AltoEmpaque"
                  value={altoEmpaque}
                  required
                  onChange={(e) => {
                    setAltoEmpaque(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Peso Empaque</Label>
                <Input
                  type="number"
                  placeholder="PesoEmpaque"
                  value={pesoEmpaque}
                  required
                  onChange={(e) => {
                    setPesoEmpaque(e.target.value);
                  }}
                />
              </Col>

            </Row>

            <Row>

            <Col md={3}>
                <Label>Stock Minimo</Label>
                <Input
                  type="number"
                  placeholder="StockMinimo"
                  value={stockMinimo}
                  required
                  onChange={(e) => {
                    setStockMinimo(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Stock Maximo</Label>
                <Input
                  type="number"
                  placeholder="StockMaximo"
                  value={stockMaximo}
                  required
                  onChange={(e) => {
                    setStockMaximo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Indice Rotacion</Label>
                <Input
                  type="number"
                  placeholder="indiceRotacion"
                  value={indiceRotacion}
                  required
                  onChange={(e) => {
                    setIndiceRotacion(e.target.value);
                  }}
                />
              </Col>
            </Row>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Linea</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedLineaEdit}
                required
                onChange={(e) => {
                  setSelectedLineaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Linea</option>
                {lineas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Marca</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedMarcaEdit}
                required
                onChange={(e) => {
                  setSelectedMarcaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Marca</option>
                {marcas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Familia</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedFamiliaEdit}
                required
                onChange={(e) => {
                  setSelectedFamiliaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Familia</option>
                {familias
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">UnidadNegocio</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedUnidadNegocioEdit}
                required
                onChange={(e) => {
                  setSelectedUnidadNegocioEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una UnidadNegocio</option>
                {unidadesNegocio
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Proveedor</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedProveedorEdit}
                required
                onChange={(e) => {
                  setSelectedProveedorEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Proveedor</option>
                {proveedores
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>  
            <Col md={3}>
                <Label className="mr-sm-2">Especiales</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={especiales}
                  required
                  onChange={(e) => {
                    setEspeciales(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            </Row>
            <Row>

            {/* </Row>
            <Row> */}

            <Col md={6}>
              <Label>
                Foto
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>  
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editArticulos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>


      <Modal size="sm" isOpen={modalPrecios} toggle={togglePrecios}>
            <ModalHeader toggle={togglePrecios}>
              <h4>Actualiza Precios</h4>
            </ModalHeader>
            <ModalBody>
            <Col>
              <Input
                required
                type="file"
                name="file"
                id="file"
                onChange={(event) => importarArchivo(event)}
              />
            </Col>
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={actualizaPrecios}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoArticulos;
