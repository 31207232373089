import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ListadoKardex() {
  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = `${process.env.REACT_APP_URL_INVENTARIOS}`;
  const URL_SEGUIMIENTOS = `${process.env.REACT_APP_URL_SEGUIMIENTOS}`;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [value, setValue] = useState("");
  const [articuloNombre, setArticuloNombre] = useState("");

  const [existenMovs, setExistenMovs] = useState(true);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function BuscaArticulo(sel) {
    setArticuloNombre(sel.SKU + " - " + sel.nombre);
    setValue(sel._id);
    setComments([]);

    // Jala Movimientos
    axios
    .get(`${URL_SEGUIMIENTOS}Kardex/${sel._id}/${selectedArea}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let existencia = 0;
      let allMovs = response.data;
  
      // Ordena de más antiguo a más nuevo para el cálculo de existencia
      let arrayOrdenado = allMovs.sort((a, b) => (a.fecha > b.fecha ? 1 : -1));
      
      // Realiza la suma de existencia
      let arrayTabla = arrayOrdenado.map((a) => {
        existencia += a.cantidad;
        return {
          fecha: a.fecha,
          tipoMov: a.tipoMov,
          numMov: a.numMov,
          destino: a.destino,
          cantidad: a.cantidad,
          existencia: existencia,
        };
      });
  
      // Ordena de más nuevo a más antiguo para presentación
      arrayTabla.sort((a, b) => (a.fecha < b.fecha ? 1 : -1));
      
      let dataFinal = Object.values(arrayTabla);
      setComments(dataFinal);
  
      if (allMovs.length == 0) {
        setExistenMovs(false);
      } else {
        setExistenMovs(true);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  function BuscaArea(event) {
    setSelectedArea(event.target.value);
    setComments([]);

    if (selectedArticulo != "") {
      // Jala Movimientos
      axios
        .get(
          `${URL_SEGUIMIENTOS}Kardex/${selectedArticulo}/${event.target.value}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let existencia = 0;
          let allMovs = response.data;
          let arrayTabla = allMovs.map((a) => {
            existencia = existencia + a.cantidad;
            return {
              fecha: a.fecha,
              tipoMov: a.tipoMov,
              numMov: a.numMov,
              destino: a.destino,
              cantidad: a.cantidad,
              existencia: existencia,
            };
          });
          let dataFinal = Object.values(arrayTabla);
          setComments(dataFinal);

          if (allMovs.length == 0) {
            setExistenMovs(false);
          } else {
            setExistenMovs(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function PDFTabla() {
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`SKU`, 20, 30);
    doc.text(`Articulo`, 20, 35);
    doc.text(`Kardex`, 20, 40);
    let existenciaPDF = 0;
    const data2 = comments.map((a) => {
      existenciaPDF = parseFloat(existenciaPDF) + parseFloat(a.cantidad);
      return [
        a.fecha,
        a.tipoMov,
        a.numMov,
        a.destino,
        new Intl.NumberFormat("en-US").format(a.cantidad),
        new Intl.NumberFormat("en-US").format(existenciaPDF),
      ];
    });
    const dataPDF2 = Object.values(data2);
    const dataPDFLimpia2 = dataPDF2.filter(function (el) {
      return el != null;
    });
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cliente/Prov",
          "Cantidad",
          "Existencia",
        ],
      ],
      body: dataPDFLimpia2,
      startY: 45,
      footStyles: {
            fontSize: 12,
            fillColor: [255,255,255],
            textColor: [0,0,0]
          },
          foot: [["", "", "", "", ""]],
      showFoot: "lastPage",
    });

    //////
    doc.save(`Kardex.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      return {
        Fecha: a.fecha,
        Movimiento: a.tipoMov,
        Numero: a.numMov,
        Destino: a.destino,
        Cantidad: a.cantidad,
      };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoKardex";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoKardex",
        sheetFilter: ["Fecha", "Movimiento", "Numero", "Destino", "Cantidad"],
        sheetHeader: ["Fecha", "Movimiento", "Numero", "Destino", "Cantidad"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Kardex`, 20, 40);
    let existenciaPDF = 0;
    const data2 = comments.map((a) => {
      existenciaPDF = parseFloat(existenciaPDF) + parseFloat(a.cantidad);
      return [
        a.fecha,
        a.tipoMov,
        a.numMov,
        a.destino,
        new Intl.NumberFormat("en-US").format(a.cantidad),
        new Intl.NumberFormat("en-US").format(existenciaPDF),
      ];
    });
    const dataPDF2 = Object.values(data2);
    const dataPDFLimpia2 = dataPDF2.filter(function (el) {
      return el != null;
    });
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cliente/Prov",
          "Cantidad",
          "Existencia",
        ],
      ],
      body: dataPDFLimpia2,
      startY: 45,
      footStyles: {
            fontSize: 12,
            fillColor: [255,255,255],
            textColor: [0,0,0]
          },
          foot: [["", "", "", "", ""]],
      showFoot: "lastPage",
    });

    //////

    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Kardex",
          email: mailTo,
          fileName: "ListadoKardex.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el seguimineto al articulo.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Movimiento", field: "tipoMov", sortable: true },
    { name: "Numero", field: "numMov", sortable: false },
    { name: "Cliente / Proveedor", field: "destino", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: false },
    { name: "Existencia", field: "existencia", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.destino.toLowerCase().includes(search.toLowerCase()) ||
          comment.tipoMov.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting]);

  const options = articulos.map((option) => {
    const junta = option.SKU + option.nombre;
    const firstLetter = option.SKU[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  let existencia = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.seguimiento_inventarios ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {/* <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button> */}
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Disponible</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Kardex</h3>

          <Row>
            <Col md={2}>
              <Label>Almacen</Label>
              <Input
                // bsSize="sm"
                type="select"
                value={selectedArea}
                required
                onChange={(e) => {
                  BuscaArea(e);
                }}
              >
                <option value="">Selecciona</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            {selectedArea != "" ? (
              <>
                <Col md={2}>
                  <Label>Codigo</Label>
                  <Input
                    // bsSize="sm"
                    type="text"
                    value={value}
                    onChange={(e) => {
                      BuscaArticulo(e);
                      setCurrentPage(1);
                    }}
                  ></Input>
                </Col>
                <Col md={2}>
                  <Label>Buscar</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedArticulo) => {
                      BuscaArticulo(selectedArticulo);
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.SKU} {option.nombre}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={4}>
                  <Label>Articulo</Label>
                  <Input
                    name="articulo"
                    type="string"
                    placeholder="Articulo"
                    value={articuloNombre}
                    disabled
                  />
                </Col>
              </>
            ) : undefined}
          </Row>
          <br />

          <Row>
            <div className="col-md-6 d-flex flex-row-reverse" id="logoutBoton">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </Row>

          <br />

          {/* Tabla Movimientos */}
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />

          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />

            <tbody>
              {existenMovs ? (
                commentsData
                .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
                .map((a) => {
                  // existencia = parseFloat(existencia) + parseFloat(a.cantidad);
                  return (
                    <tr>
                      <td>{a.fecha}</td>
                      <td>{a.tipoMov}</td>
                      <td>{a.numMov}</td>
                      <td>{a.destino}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.cantidad)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.existencia)}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>No se encontraron Movimientos...</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}

      {loader}
    </>
  );
}

export default ListadoKardex;
