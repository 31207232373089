import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Badge,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { parse, v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";

function ListadoControlCalidad() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CONTROL_CALIDAD = process.env.REACT_APP_URL_CONTROL_CALIDAD;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;

  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);


  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalSubirFoto, setModalSubirFoto] = useState(false);
  const toggleSubirFoto = () => setModalSubirFoto(!modalSubirFoto);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState([]);
  const [idNotaCXP, setIdNotaCXP] = useState();
  const [idControlCalidad, setIdControlCalidad] = useState();
  const URL_UPMEDIA = process.env.REACT_APP_URL_UPMEDIA;

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [idEdit, setIdEdit] = useState("");
  const [numero_orden, setNumeroOrden] = useState("");
  const [articulosOrden, setArticulosOrden] = useState([]);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [totalCantidadDanado, setTotalCantidadDanado] = useState(0);
  const [totalCantidadFaltante, setTotalCantidadFaltante] = useState(0);
   const [inputFields, setInputFields] = useState([
    {
      id: "",
      idArticulos: "",
      articulos: "",
      porcentajeDanado: 0,
      danado: 0,
      porcentajeFaltante: 0,
      faltante: 0,
      descuentoTotal: 0,
      descuentoFaltante: 0,
      descuentoDanado: 0,
      cantidad:0,
      total_costo:0
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [articulosEmb, setArticulosEmb] = useState([]);
  const [inputFieldsNuevos, setInputFieldsNuevos] = useState([
    {
      id: uuidv4(),
      idArticulosEmabrque: "",
      porcentajeDanado: 0,
      danado: 0,
      porcentajeFaltante: 0,
      faltante: 0,
      descuentoTotal:0,      descuentoFaltante: 0,
      descuentoDanado: 0,
      cantidad:0,
      total_costo:0
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_CONTROL_CALIDAD, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCompras = response.data;
        // Array para pagination
        let arrayTabla = allCompras
          .sort((a, b) => (a.idNotasCxP < b.idNotasCxP ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              is_active: a.is_active,
              fecha: a.fecha,
              idControlCalidad: a.idControlCalidad,
              familia: a.familia,
              idProveedor: a.proveedores[0]._id,
              proveedor: a.proveedores[0].nombre_comercial,
              modelo: a.modelo,
              total: a.total
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
    .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_ARTICULOS_EMBARQUES}ControlCalidadCreate`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosEmb = res.data;
       
        setArticulosEmb(allArticulosEmb);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.idControlCalidad,
          a.fecha,
          a.familia,
          a.proveedores,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado de Control de Calidad`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Familia",
          "Proveedor",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`ControlCalidad.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return {
          Numero: a.idControlCalidad,
          Fecha: a.fecha,
          Familia: a.familia,
          Proveedores: a.proveedores,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoControlCalidad";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoControlCalidad",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Familia",
          "Proveedor",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Familia",
          "Proveedor",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.idControlCalidad,
          a.fecha,
          a.familia,
          a.proveedores,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado de Control de Calidad`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Familia",
          "Proveedor",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Control de Calidad",
          email: mailTo,
          fileName: "ListadoControlCalidad.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Control de Calidad.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    proveedor,
    familia,
    modelo,
    idControlCalidad,
    total,
    idPDFOC
  ) {

    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idPDFOC}`;
    await axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
          let photoPDF = data.data.resources

     axios
      .get(`${URL_ARTICULOS_EMBARQUES}/controlCalidad/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosCompra = response.data;
        let arrayTabla = allArticulosCompra
          .sort((a, b) => (a.captura > b.captura ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " / " + a.articulos[0].nombre,
              // sup: a.sup,
              // familias: a.familias,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              cantidad: a.cantidad,
              fob: parseFloat(a.costo),
              impuestos: parseFloat(a.impuestos),
              total_costo:
                parseFloat(a.precio) + parseFloat(a.impuestos),
              revisarFaltante: parseFloat(a.revisarFaltante),
              cantidadRevisarFaltante:
                parseFloat(a.cantidad) / parseFloat(a.revisarFaltante),
              faltante: parseFloat(a.faltante),
              porFaltante: parseFloat(a.revisarFaltante),
              faltanteTotal: parseFloat(a.faltanteTotal),
              porFaltantePedir: a.pedirFaltante,
              descuentoFaltante: a.descuentoFaltante,
              revisarDanado: parseFloat(a.revisarDanado),
              cantidadRevisarDanado:
                parseFloat(a.cantidad) / parseFloat(a.revisarDanado),
              danado: parseFloat(a.danado),
              porDanado: parseFloat(a.revisarDanado),
              danadoTotal: parseFloat(a.danadoTotal),
              porDanadoPedir: a.pedirDanado,
              descuentoDanado: a.descuentoDanado,
              descuentoTotal: a.descuentoTotal,
              observaciones: a.observaciones
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let faltanteTotal = dataFinal.map((c) => parseFloat(c.faltanteTotal));
        let TF = faltanteTotal.reduce((t, total, index) => t + total, 0);

        let danadoTotal = dataFinal.map((c) => parseFloat(c.danadoTotal));
        let TD = danadoTotal.reduce((t, total, index) => t + total, 0);

        let descuentoTotal = dataFinal.map((c) => parseFloat(c.descuentoTotal));
        let TT = descuentoTotal.reduce((t, total, index) => t + total, 0);



        const data = dataFinal.map((a) => {
          return [
            a.articulos,
            a.cantidad,
            new Intl.NumberFormat("en-US").format(a.faltanteTotal),
            new Intl.NumberFormat("en-US").format(a.danadoTotal),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.descuentoFaltante),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.descuentoDanado),
             new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.fob),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.descuentoTotal),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        const doc = new jsPDF("landscape", "mm", "a4", true);
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 240, 10, 18, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 45, 15);
        doc.text(`Quality Control Management`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Date ${fecha}`, 20, 25);
        doc.text(`Brand: ${process.env.REACT_APP_NOMBRE_INSTITUCION}`, 20, 30);
        doc.text(`Supplier: ${proveedor}`, 20, 35);
        doc.text(
          `Missing Pcz: ${new Intl.NumberFormat("en-US").format(TF)}`,
          20,
          45
        );
        doc.text(`Family: ${familia}`, 20, 50);
        doc.text(
          `Defects Pcz: ${new Intl.NumberFormat("en-US").format(TD)}`,
          20,
          55
        );
        doc.text(
          `Claim Amount: ${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
            
          }).format(total)}`,
          20,
          60
        );
        doc.autoTable({
          head: [
            [
              "Style",
              "Shiped Qty",
              "Total Missing",
              "Total Damage",
              "Missing Discount",
              "Damaged Discount",
              "FOB",
              "Total Discount",
            ],
          ],
          body: dataPDFLimpia,
          startY: 65,
          showFoot: "lastPage",
        });

        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Description ${modelo}`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);
        
        doc.text(
          `______________________                                     ______________________ `,
          40,
          185
        );
        doc.text(
          `      Zury Sivan                                                             Supplier `,
          50,
          190
        );
        doc.addPage();

        let alto = 10
        let contador = 1
        let contadorPagina = 1
        let derecha = 10

        photoPDF.map((a)=>{
          if(contador % 5 == 0){
            alto = alto + 60
            derecha = 10
            contador = 1
          }
          if(contadorPagina % 13 == 0){
            doc.addPage()
            alto = 10
            derecha = 10
            contadorPagina = 1
          }
          var imgP = new Image();
          imgP.src = a.secure_url
          doc.addImage(imgP, a.format, derecha, alto, 50, 50);
          derecha = derecha + 60
          contador = contador + 1
          contadorPagina = contadorPagina + 1
        })

        doc.save(`ReporteCalidad-${idControlCalidad}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
      
    })
    .catch((err) => console.log(err));
  }

  const headers = [
    { name: "Numero", field: "idControlCalidad", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Familia", field: "familia", sortable: true },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Total", field: "total", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.familia.toLowerCase().includes(search.toLowerCase()) ||
          comment.total.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }


    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "idControlCalidad" &&
      sorting.field != "total" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "idControlCalidad"  || sorting.field == "total" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "idControlCalidad"  || sorting.field == "total" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedProveedor,
    selectedFechaInicio,
    selectedFechaFin,
  ]);


  function jalaFoto(
    idNota
  ) {
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idNota}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
          setPhoto(data.data.resources);
      })
      .catch((err) => console.log(err));
    setIdNotaCXP(idNota);
    toggleFoto();
  }

  function guardaFotos(){

    const formData = new FormData();
    formData.append("file", file);
    formData.append("notasCXP", idNotaCXP );

    axios
      .post(URL_UPMEDIA, formData, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })

    axios.post(
      URL_LOGS,
      {
        tipo: "Control Calidad",
        detalle: `${idControlCalidad}`,
        user: user.id,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
        },
      }
    );
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Bienvenido",
      showConfirmButton: false,
      timer: 2000,
    });

    setFile()
    setPhoto([])
  }

  function cancelar(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .get(`${URL_CONTROL_CALIDAD}Cancel/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }

  function EditArticulos(idOC, numero_orden) {
    setInputFields([
      {
        id: "",
        idArticulos: "",
        articulos: "",
        porcentajeDanado: 0,
        danado: 0,
        porcentajeFaltante: 0,
        faltante: 0,
        descuentoTotal: 0,
        descuentoFaltante: 0,
        descuentoDanado: 0,
        cantidad:0,
        total_costo:0
      },
    ]);
    setIdEdit(idOC);
    setNumeroOrden(numero_orden);
    axios
      .get(`${URL_ARTICULOS_EMBARQUES}/controlCalidad/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrden = response.data;
        let arrayTabla = allArticulosOrden
          .map((a) => {
            return {
              id: a._id,
              idArticulos: a.articulos[0]._id,
              articulos: a.articulos[0].nombre + " " + a.embarques[0].contenedor,
              danado: a.danadoTotal,
              porcentajeDanado: a.pedirDanado,
              faltante: a.faltanteTotal,
              porcentajeFaltante: a.pedirFaltante,
              descuentoTotal: a.descuentoTotal,
              descuentoFaltante: a.descuentoFaltante,
              descuentoDanado: a.descuentoDanado,
              cantidad: a.cantidad,
              total_costo: a.costo + a.impuestos
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);

        let dan = dataFinal.map((c) => parseFloat(c.danado));
        let TCD = dan.reduce((t, total, index) => t + total, 0);
        setTotalCantidadDanado(TCD);
        let falt = dataFinal.map((c) => parseFloat(c.faltante));
        let TCF = falt.reduce((t, total, index) => t + total, 0);
        setTotalCantidadFaltante(TCF);
        let importes = dataFinal.map((c) => parseFloat(c.descuentoTotal));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }


  async function borrarExistentes(id){
    Swal.fire({
      title: "Estas seguro?",
      text: "Se va a Borrar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Borrar!",
    }).then(async(result) => {
      if (result.isConfirmed) {
     
      
      axios
      .patch(
        `${URL_ARTICULOS_EMBARQUES}/calidad/${id}`,
        {
          calidad: "No",
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
          },
        }
      ).then(()=>{

        const values = [...inputFields];
        values.splice(
          values.findIndex((value) => value.id === id),
          1
        );
        setInputFields(values);
        
        let importes = values.map((c) => parseFloat(c.descuentoTotal));
        let TG = importes.reduce((t, total, index) => t + total, 0);


        axios
        .patch(
          `${URL_CONTROL_CALIDAD}/${idEdit}`,
          {
            total: TG,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        ).then(()=>{
          Swal.fire("Good job!", "Creado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }).catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
      }).catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
      }
    });
  }


  function BuscaArticulo(id, event) {
    articulosEmb.map((a) => {
      if (a._id == event.target.value) {
        let idArtEmb = a._id;
        let danado = a.danadoTotal;
        let porcentajeDanado = a.pedirDanado;
        let faltante = a.faltanteTotal;
        let porcentajeFaltante = a.pedirFaltante;
        let descuentoTotal = a.descuentoTotal
        let descuentoFaltante = a.descuentoFaltante
        let descuentoDanado = a.descuentoDanado
        let cantidad = a.cantidad
        let total_costo = a.costo + a.impuestos
        handleChangeInput(id, idArtEmb, porcentajeDanado, danado, porcentajeFaltante, faltante, descuentoTotal, descuentoFaltante, descuentoDanado, cantidad, total_costo);
      }
    });
}

const handleChangeInput = (id, idArtEmb, porcentajeDanado, danado, porcentajeFaltante, faltante, descuentoTotal, descuentoFaltante, descuentoDanado, cantidad, total_costo) => {
  const newInputFieldsNuevos = inputFieldsNuevos.map((i) => {
    if (id === i.id) {
      i.idArticulosEmabrque = idArtEmb;
      i.porcentajeDanado = porcentajeDanado
      i.danado = danado
      i.faltante = faltante
      i.porcentajeFaltante = porcentajeFaltante
      i.descuentoTotal = descuentoTotal
      i.descuentoFaltante = descuentoFaltante
      i.descuentoDanado = descuentoDanado
      i.cantidad = cantidad
      i.total_costo = total_costo
    }
    return i;
  });
  setInputFieldsNuevos(newInputFieldsNuevos);
  ActualizaTotales()
};

const handleAddFields = () => {

  setInputFieldsNuevos([
    ...inputFieldsNuevos,
    {
      id: uuidv4(),
      idArticulosEmabrque: "",
      porcentajeDanado: 0,
      danado: 0,
      porcentajeFaltante: 0,
      faltante: 0,
      descuentoTotal:0,
      descuentoFaltante: 0,
      descuentoDanado: 0,
      cantidad:0,
      total_costo:0
    },
  ]);
};

const handleRemoveFields = (id) => {
  const values = [...inputFieldsNuevos];
  values.splice(
    values.findIndex((value) => value.id === id),
    1
  );
  setInputFieldsNuevos(values);
 
};

function ActualizaTotales() {


  let dan = inputFields.map((c) => parseFloat(c.danado));
  let TCD = dan.reduce((t, total, index) => t + total, 0);
  let danN = inputFieldsNuevos.map((c) => parseFloat(c.danado));
  let TCDN = danN.reduce((t, total, index) => t + total, 0);
  setTotalCantidadDanado(TCD + TCDN);

  let falt = inputFields.map((c) => parseFloat(c.faltante));
  let TCF = falt.reduce((t, total, index) => t + total, 0);
  let faltN = inputFieldsNuevos.map((c) => parseFloat(c.faltante));
  let TCFN = faltN.reduce((t, total, index) => t + total, 0);
  setTotalCantidadFaltante(TCF + TCFN);

  let cant = inputFields.map((c) => parseFloat(c.descuentoTotal));
  let TC = cant.reduce((t, total, index) => t + total, 0);
  let cantNuevos = inputFieldsNuevos.map((c) => parseFloat(c.descuentoTotal));
  let TCN = cantNuevos.reduce((t, total, index) => t + total, 0);
  setTotalGeneralEdit(TC + TCN);
}

const saveControlCalidad = async (event) => {
  event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFieldsNuevos.length + inputFields.length;
    try {
      await axios
      .patch(
        `${URL_CONTROL_CALIDAD}/${idEdit}`,
          {
            total: total_general_edit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFieldsNuevos.map((a) => {
            if(a.idArticulosEmabrque != ""){
              
              axios
                .patch(
                  `${URL_ARTICULOS_EMBARQUES}/calidad/${a.idArticulosEmabrque}`,
                  {
                    controlCalidad: data.data._id,
                    calidad:"Si"
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Editar Control Calidad",
                          detalle: `CC ${idEdit}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
                
            }else{
              totalArticulos = totalArticulos - 1;
            }
          });

          inputFields.map((r) => {
            axios
              .patch(
                `${URL_ARTICULOS_EMBARQUES}/calidad/${r.id}`,
                {
                  pedirDanado: r.porcentajeDanado,
                  pedirFaltante: r.porcentajeFaltante,
                  descuentoTotal: r.descuentoTotal,
                  descuentoFaltante: r.descuentoFaltante,
                  descuentoDanado: r.descuentoDanado,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Editar Control Calidad",
                        detalle: `CC ${idEdit}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
        });


        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
};

const handleChangeInputPorFaltantePedir = (id, event) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      i[event.target.name] = parseFloat(event.target.value);
      i.descuentoFaltante =
        (parseFloat(event.target.value) / 100) *
        parseFloat(i.cantidad) *
        i.total_costo;
      i.descuentoTotal =
        (parseFloat(event.target.value) / 100) *
          parseFloat(i.cantidad) *
          i.total_costo +
        i.descuentoDanado;
    }
    return i;
  });
  setInputFields(newInputFields);
  ActualizaTotales();

};


const handleChangeInputPorDanadoPedir = (id, event) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      i[event.target.name] = parseFloat(event.target.value);

      i.descuentoDanado = (parseFloat(event.target.value) / 100) * parseFloat(i.cantidad) * i.total_costo;
      
        i.descuentoTotal =
        (parseFloat(event.target.value) / 100) *
          parseFloat(i.cantidad) *
          i.total_costo +
        i.descuentoFaltante;
    }
    return i;
  });
  setInputFields(newInputFields);
  ActualizaTotales();
};

console.table(inputFields)

return (
    <>
      <Header />
      <br />
      <br />
      {user.menuCalidad ? (
        <div className="card container col-11">
            <div>
              <Row>
                <Col md={8}>
                {user.menu_compras ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ControlCalidadCreate"
                >
                  Nuevo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo
                </Button>
              )}
                  <Button
                    size="sm"
                    href="/MenuCompras"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>

              <h3 align="center">Control de Calidad</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Proveedores</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedProveedor}
                    onChange={(e) => {
                      setSelectedProveedor(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {proveedores
                      .sort((a, b) =>
                        a.razon_social > b.razon_social ? 1 : -1
                      )
                      .map((a) => {
                        return <option value={a._id}>{a.razon_social}</option>;
                      })}
                  </Input>
                </Col>

              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                  {commentsData.map((a) => {
                    if(a.is_active == "Si"){
                      return (
                        <tr>
                          <td>{a.idControlCalidad}</td>
                          <td>{a.fecha}</td>
                          <td>{a.familia}</td>
                          <td>{a.proveedor}</td>
                          <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)}</td>
                         
                          <td>
                              <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button>
                            <Button
                              color="success"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>{
                                setIdNotaCXP(a._id)
                                setIdControlCalidad(a.idControlCalidad)
                                toggleSubirFoto()
                              }}
                            >
                              <i class="fas fa-upload"></i>
                            </Button>
                            <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) =>
                                  PDFOC(
                                    a.fecha,
                                    a.proveedor,
                                    a.familia,
                                    a.modelo,
                                    a.idControlCalidad,
                                    a.total,
                                    a._id
                                  )
                                }
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button>

                              <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => EditArticulos(a._id, a.idControlCalidad)}
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>

                              <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) => cancelar(a._id)}
                            >
                              <i class="fas fa-ban"></i>
                            </Button>
                          </td>
                        </tr>
                      );}else{
                        return (
                          <tr style={{ backgroundColor: "#ed8c8c"}}>
                            <td>{a.idControlCalidad}</td>
                            <td>{a.fecha}</td>
                            <td>{a.familia}</td>
                            <td>{a.proveedor}</td>
                            <td></td>
                             <td>CANCELADA </td>
                          </tr>
                        );                        
                      }
                  })}
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
         
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
        <ModalHeader toggle={toggleMail}>
          <h4>Enviar Listado Ordenes de Compra</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={enviaMail}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Fotos
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo.length > 0 ? (
              photo.map((a)=>{
                return(
                <h6 style={{ textAlign: "center" }}>
                <img
                  src= {a.secure_url}
                  width="250"
                  height="auto"
                   />
              </h6>
              )                
              })
            ) : <h4>No se ha subido una Foto....</h4>}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalSubirFoto} toggle={toggleSubirFoto}>
        <ModalHeader toggle={toggleSubirFoto}>
          <h4>
            Subir Fotos
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
                  <Col md={6}>
              <Label>
                Selecciona un archivo
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
                  </Row>
            <Button size="sm" color="success" onClick={guardaFotos}>
            Guardar
          </Button>
        </ModalBody>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Articulos Control Calidad {numero_orden}</h4>
          <h4>
            TOTALES {totalCantidadDanado} pzas. dañadas /{" "}
            {totalCantidadFaltante} pzas. faltantes /{" "}
            {"$" + new Intl.NumberFormat("en-US").format(total_general_edit)}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">% Dañado</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Dañado Total</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">% Faltante</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Faltante Total</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Desc. Total $</Label>
            </Col>
          </Row>

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={3}>
                  <Input
                    // bsSize="sm"
                    name="articulo"
                    type="text"
                    value={inputField.articulos}
                    required
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="porcentajeDanado"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="NA"
                    value={inputField.porcentajeDanado}
                    required
                    onChange={(event) => {
                      handleChangeInputPorDanadoPedir(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="danado"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="NA"
                    value={inputField.danado}
                    required
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    // bsSize="sm"
                    name="porcentajeFaltante"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="NA"
                    value={inputField.porcentajeFaltante}
                    required
                    onChange={(event) => {
                      handleChangeInputPorFaltantePedir(inputField.id, event);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="faltante"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="NA"
                    value={inputField.faltante}
                    required
                    disabled
                  />
                </Col>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="descuentoTotal"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="NA"
                    value={inputField.descuentoTotal}
                    required
                    disabled
                  />
                </Col>

                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => borrarExistentes(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}



{inputFieldsNuevos.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      name="articulos"
                      type="select"
                      value={inputField.idArticulosEmabrque}
                      required
                      onChange={(event) =>
                        BuscaArticulo(inputField.id, event)
                      }
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosEmb
                      .sort((a, b) => (a.articulos[0].nombre > b.articulos[0].nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                               {a.articulos[0].nombre} / {a.embarques[0].contenedor}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      name="porcentajeDanado"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.porcentajeDanado}
                    disabled
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      name="danado"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.danado}
                    disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      name="porcentajeFaltante"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.porcentajeFaltante}
                    disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      name="faltante"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.faltante}
                    disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      name="descuentoTotal"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.descuentoTotal}
                      disabled
                    ></Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFieldsNuevos.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
          
                </Row>
              </div>
            ))}

          {/* <Row>
            <Col md={6}>
              <h4 id="logoutBoton">
                TOTALES {total_cantidad_edit} pzas. /{" "}
                {"$" +
                  new Intl.NumberFormat("en-US").format(total_general_edit)}
              </h4>
            </Col>
          </Row> */}
        </ModalBody>
        <ModalFooter>

          {validaBoton ? (
              <Button size="sm" color="success"  onClick={saveControlCalidad}>
                Guardar
              </Button>
            ) : (
              <Button size="sm" color="success"   disabled>
                Guardar
              </Button>
            )}
        </ModalFooter>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoControlCalidad;
